<template>
    <div class="app-container">
        <el-card>
            <h2>教师档案资料填报管理</h2>
            <el-row>
                <el-col :span="6">
                    <el-select clearable @change="changeSchool" v-model="orgId" placeholder="请选择学校">
                        <el-option v-for="item in schoolList" :key="item.orgId" :label="item.orgName" :value="item.orgId" />
                    </el-select>
                </el-col>
            </el-row>
            <div class="tip custom-block">
                <div class="ml10" style="font-size: 17px;font-weight: bold;">
                    进度
                </div>
            </div>
            <div>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="填报进度">
                            <el-progress style="100%" :text-inside="true" :stroke-width="20" :percentage="Math.floor(yTb/(yTb+nTb)?yTb/(yTb+nTb):0)*100" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <span class="ml15" style="line-height: 30px;">{{ Math.floor(yTb/(yTb+nTb)?yTb/(yTb+nTb):0)*100 }}%</span><span class="ml15">{{yTb}}已填报</span><span class="ml15" style="color: red;">{{nTb}}未填报</span>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="审核进度">
                            <el-progress style="100%" :text-inside="true" :stroke-width="20" :percentage="Math.floor(ySh/(ySh+nSh)?ySh/(ySh+nSh):0)*100" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <span class="ml15" style="line-height: 30px;">{{Math.floor(ySh/(ySh+nSh)?ySh/(ySh+nSh):0)*100}}%</span><span class="ml15">{{ySh}}已完成审核</span><span class="ml15" style="color: red;">{{nSh}}未完成审核</span>
                    </el-col>
                </el-row>
            </div>
            <div class="tip custom-block">
                <div class="ml10" style="font-size: 17px;font-weight: bold;">
                    填报详情一览
                </div>
            </div>
            <el-form :model="queryParams" ref="queryForm" :inline="true" label-width="68px"
                @submit.native.prevent="handleQuery">
                <el-row class="mt15">
                    <!-- <el-col :span="6" class="mb0">
                        <el-form-item label="学校">
                            <el-select clearable @change="changeSchool" v-model="orgId" placeholder="请选择学校">
                                <el-option v-for="item in schoolList" :key="item.orgId" :label="item.orgName"
                                    :value="item.orgId" />
                            </el-select>
                        </el-form-item>
                    </el-col> -->
                    <el-col :span="6" class="mb0">
                        <el-form-item label="学段">
                            <el-select clearable @change="changePeriod" v-model="queryParams.periodKey" placeholder="请选择学科">
                                <el-option v-for="item in periodList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="年级">
                            <el-select clearable @change="changeGrade" v-model="queryParams.gradeKey" placeholder="请选择年级">
                                <el-option v-for="item in gradeList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item label="学科">
                            <el-select clearable @change="getList()" v-model="queryParams.subjectKey" placeholder="请选择学科">
                                <el-option v-for="item in subjectList" :key="item.dictKey" :label="item.dictValue"
                                    :value="item.dictKey" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" class="mb0">
                        <el-form-item class="pull-right">
                            <el-input v-model="queryParams.trueName" placeholder="请输入老师名称" class="input-with-select">
                                <template #append>
                                    <el-button @click="getList" type="primary" icon="el-icon-search" />
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <el-row>
                    <el-col :span="24" class="mb0 mt15">
                        <el-form-item class="pull-right">
                            <el-input v-model="queryParams.trueName" placeholder="请输入老师名称" class="input-with-select">
                                <template #append>
                                    <el-button @click="getList" type="primary" icon="el-icon-search" />
                                </template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row> -->
            </el-form>
            <el-divider />
            <div class="bre9e mt15">
                <el-table header-cell-class-name="bgf2" show-overflow-tooltip @current-change="handleCurrentChange"
                    :height="`calc(100vh - 490px)`" v-loading="loading" :data="dataList" highlight-current-row>
                    <el-table-column type="index" label="序号" width="60" />
                    <el-table-column label="教师" prop="trueName">
                        <template #default="scope">
                            {{ scope.row.trueName }}
                        </template>
                    </el-table-column>
                    <el-table-column label="学段" prop="periodName" />
                    <el-table-column label="年级" prop="gradeName" />
                    <el-table-column label="学科" prop="subjectName" />
                    <el-table-column label="填报详情">
                        <template #default="scope">
                            <div v-if="scope.row.fillStatus == 0" class="" style="color: #f56c6c;">未完成</div>
                            <div v-if="scope.row.fillStatus == 1" class="" style="color: #67c23a;">已完成</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="审核状态">
                        <template #default="scope">
                            <div @click="goCheck(scope.row)" v-if="scope.row.checkStatus == 0" class="cursor"
                                style="color: #f56c6c;">未分配</div>
                            <div @click="goCheck(scope.row)" v-if="scope.row.checkStatus == 1" class="cursor"
                                style="color: #409eff;">待审核</div>
                            <div @click="goCheck(scope.row)" v-if="scope.row.checkStatus == 2" class="cursor"
                                style="color: #67c23a;">通过</div>
                            <div @click="goCheck(scope.row)" v-if="scope.row.checkStatus == 3" class="cursor"
                                style="color: #f80808;">未通过</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template #default="scope">
                            <div style="color: #409eff;" @click="qxCheck(scope.row)" v-if="scope.row.checkStatus != 0" class="cursor">取消分配</div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
    </div>
</template>

<script>
import { archiveManagerList, archiveManagerCheckList,archiveManagerCancelDivide } from '@/api/admin/archive';
import { coursewarePage } from "@/api/admin/famousCourse"
import { timestampToTime, timestampToDate } from "@/utils/date";
import { queryDict, orgListUserSchool } from "@/api/admin/public";

export default {
    name: "fileManage",
    components: {
    },
    data() {
        return {
            nTb:0,
            yTb:0,
            nSh:0,
            ySh:0,
            schoolList: [],
            orgId: '',
            subjectList: [],
            periodList: [],
            gradeList: [],
            timestampToDate,
            token: this.$storage.get("token"),
            courseClassifyList: [],
            timestampToTime,
            // 遮罩层
            loading: true,
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            // 弹出层标题
            title: "",
            // 是否显示弹出层
            open: false,
            // 查询参数
            queryParams: {
                trueName: '',
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
            },
            groupList: [],
            selectObj: [],
            selectList: [],
            fillTeacherList: [],
        };
    },
    created() {
        console.log(0/0);
        console.log((this.yTb+this.nTb));

        console.log(this.yTb/(this.yTb+this.nTb));
        console.log(Math.floor(this.yTb/(this.yTb+this.nTb)));
        this.getQueryDict()
        this.getSchoolList();
        this.getList();
    },
    activated() {
        if (this.$route.query.isSx == 1) {

        }
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        getSchoolList() {
            orgListUserSchool().then(res => {
                this.schoolList = res.data.data;
            })
        },
        qxCheck(row){
            archiveManagerCancelDivide({userId:row.userId }).then(res => {
                if(res.data.code == 1){
                    this.getList();
                    this.$message.success('取消成功')
                }else{
                    if(res.data.msg){
                        this.$message.error(res.data.msg)
                    }else{
                        this.$message.error('操作失败')
                    }
                }
            })
        },
        goCheck(row) {
            console.log(row);
            let routeUrl = this.$router.resolve({
                path: '/fileFillck',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { userId: row.userId, checkStatus: row.checkStatus,isRead:true }
            })
            window.open(routeUrl.href, '_blank')
            // window.location.assign(routeUrl.href)
        },
        getQueryDict() {
            queryDict({ code: 'famous_course_classify' }).then(res => {
                this.courseClassifyList = res.data.data;
            })
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
        },
        changePeriod() {
            this.queryParams.gradeKey = '';
            this.queryParams.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.queryParams.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
            this.getList()
        },
        changeSchool() {
            this.queryParams = {
                trueName: '',
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                checkTrueName: '',
            }
            this.getList()
        },
        changeGrade() {
            this.queryParams.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.queryParams.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.queryParams.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
            this.getList()
        },
        /** 查询基础：基数数据列表 */
        getList(e) {
            this.nTb = 0
            this.yTb = 0
            this.nSh = 0
            this.ySh = 0
            this.loading = true;
            this.dataList = [];
            if (this.orgId) {
                archiveManagerList({ orgId: this.orgId }).then(res => {
                    res.data.data.forEach(e=>{
                        if(e.fillStatus == 1){
                            this.yTb++;
                        }else{
                            this.nTb++;
                        }
                        if(e.checkStatus == 2 || e.checkStatus == 3 ){
                            this.ySh++;
                        }else{
                            this.nSh++;
                        }
                    })
                    if (this.queryParams.periodKey && this.queryParams.periodKey !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.periodKey == this.queryParams.periodKey
                        })
                    }
                    if (this.queryParams.gradeKey && this.queryParams.gradeKey !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.gradeKey == this.queryParams.gradeKey
                        })
                    }
                    if (this.queryParams.subjectKey && this.queryParams.subjectKey !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.subjectKey.toString().indexOf(this.queryParams.subjectKey) != -1
                        })
                    }
                    if (this.queryParams.trueName && this.queryParams.trueName !== '') {
                        res.data.data = res.data.data.filter(itemA => {
                            return itemA.trueName.toString().indexOf(this.queryParams.trueName) != -1
                        })
                    }
                    this.loading = false;
                    this.dataList = res.data.data;
                });
            } else {
                this.loading = false;
            }

        },



    }
};
</script>
<style scoped>
.el-progress{
    width: 100%;
}
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}

.zzzdddyyy /deep/ .el-upload-list {
    margin: 0 !important;
}

.zzzdddyyy /deep/ .el-upload-list__item {
    display: none;
}

.pxbutton {
    width: 90px;
    padding: 7px 15px;
    text-align: center;
    cursor: pointer;
    border: 1px solid #e4e7ed;
    font-size: 13px;
}

.filtxt {
    color: #409efe;
    margin: 8px 0;
}

.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}
</style>