import request from "@/utils/request";
// 整体提交档案填报
export const archiveFillBatch =(data) =>{
    return request({
        url: "/businessservice/archive/fill/batch",
        method:"post",
        data: data
    })
}
// 删除档案填报
export const  archiveFillDelete =(query) =>{
    return request({
        url: "/businessservice/archive/fill/delete",
        method:"get",
        params: query
    })
}
// 单条档案填报（修改或更新）
export const archiveFillSingle =(data) =>{
    return request({
        url: "/businessservice/archive/fill/single",
        method:"post",
        data: data
    })
}
// 上传档案文件
export const archiveFillUploadFile =(data) =>{
    return request({
        url: "/businessservice/archive/fill/uploadFile",
        method:"post",
        data: data
    })
}
// 查询教师档案
export const  archiveQueryArchive =(query) =>{
    return request({
        url: "/businessservice/archive/query/archive",
        method:"get",
        params: query
    })
}
// 档案审核-保存审核结果
export const archiveManagerCheckArchive =(data) =>{
    return request({
        url: "/businessservice/archiveManager/check/archive",
        method:"post",
        data: data
    })
}
// 档案审核-查询审核列表
export const  archiveManagerCheckList =(query) =>{
    return request({
        url: "/businessservice/archiveManager/check/list",
        method:"get",
        params: query
    })
}
// 填报管理-取消分配
export const  archiveManagerCancelDivide =(query) =>{
    return request({
        url: "/businessservice/archiveManager/manager/cancelDivide",
        method:"get",
        params: query
    })
}
// 填报管理-查询组织档案审核人列表
export const  archiveManagerCheckUserList =(query) =>{
    return request({
        url: "/businessservice/archiveManager/manager/checkUserList",
        method:"get",
        params: query
    })
}
// 填报管理-分配审核人
export const archiveManagerDivide =(data) =>{
    return request({
        url: "/businessservice/archiveManager/manager/divide",
        method:"post",
        data: data
    })
}
// 填报管理-查询填报管理列表
export const  archiveManagerList =(query) =>{
    return request({
        url: "/businessservice/archiveManager/manager/list",
        method:"get",
        params: query
    })
}
export const allList = [
    {
        smTit: '第一部分:教师基础信息',
        smId: 'part1',
        smList: [
            { topCategory: 1, secondCategory: 1, key: 'gender', value: '', title: '性别' },
            { topCategory: 1, secondCategory: 2, key: 'birthDate', value: '', title: '出生日期' },
            { topCategory: 1, secondCategory: 3, key: 'politicalClimate', value: '', title: '政治面貌' },
            { topCategory: 1, secondCategory: 4, key: 'nation', value: '', title: '民族' },
            {
                topCategory: 1, secondCategory: 5, key: '', tsList: [
                    { key: 'qualification', title: '全日制学校毕业学历' },
                    { key: 'timeList', title: '起止时间' },
                    { key: 'orgName', title: '毕业院校' },
                    { key: 'major', title: '毕业专业' },
                    { key: 'isSelect', title: '是否师范类专业' },
                    { key: 'annex1UrlList', title: '毕业证书' },
                    { key: 'annex2UrlList', title: '学位证书' },
                ], value: [], title: '全日制学校毕业学历(备注:只填写入职前全日制学校毕业的最高学历)', bgTit: '学历和工作信息', bgShow: true
            },
            {
                topCategory: 1, secondCategory: 6, key: '', tsList: [
                    { key: 'qualification', title: '全日制学校毕业学历' },
                    { key: 'timeList', title: '起止时间' },
                    { key: 'orgName', title: '毕业院校' },
                    { key: 'major', title: '毕业专业' },
                    { key: 'isSelect', title: '是否师范类专业' },
                    { key: 'annex1UrlList', title: '毕业证书' },
                    { key: 'annex2UrlList', title: '学位证书' },
                ], value: [], title: '最高学历', bgTit: '学历和工作信息', bgShow: false
            },
            { topCategory: 1, secondCategory: 7, key: 'teachAge', value: '', title: '教龄' },
            { topCategory: 1, secondCategory: 8, key: 'workAge', value: '', title: '工龄' },
            { topCategory: 1, secondCategory: 9, key: 'isSelect', value: '', title: '是否从事一线教学', optionList: ['正在从事一线教学', '目前未从事一线教学'] },
            {
                topCategory: 1, secondCategory: 10, key: '', tsList: [
                    { key: 'name', title: '职称', optionName: 'technical_title' },
                    { key: 'startTime', title: '评定时间' },
                    { key: 'annex1UrlList', title: '证明材料' },
                ], value: [], title: '现专业技术职称(请填写目前最高股称)', bgTit: '', bgShow: false
            },
            {
                topCategory: 1, secondCategory: 11, key: '', tsList: [
                    { key: 'periodSubjectList', title: '学段/学科' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '请上传持有的教师资格证，如有多个，请逐一上传', bgTit: '', bgShow: false
            },
            {
                topCategory: 1, secondCategory: 12, key: '', tsList: [
                    { key: 'prizeLevel', title: '等级', optionName: 'mandarin_level' },
                    { key: 'startTime', title: '通过时间' },
                    { key: 'annex1UrlList', title: '合格证书' },
                ], value: [], title: '请上传普通话水平测试等级证书', bgTit: '', bgShow: false
            },
            {
                topCategory: 1, secondCategory: 13, key: '', tsList: [
                    { key: 'orgName', title: '工作单位' },
                    { key: 'timeList', title: '起止时间' },
                    { key: 'content', title: '工作内容' },
                    { key: 'remarks', title: '备注' },
                ], value: [], title: '【主要工作经历】分阶段填写毕业至今的主要工作经历(包括本校的工作经历，截止时间到本月)', bgTit: '主要工作经历和管理工作', bgShow: true
            },
            {
                topCategory: 1, secondCategory: 14, key: '', tsList: [
                    { key: 'orgName', title: '工作单位' },
                    { key: 'timeList', title: '起止时间' },
                    { key: 'content', title: '工作内容' },
                    { key: 'remarks', title: '备注' },
                    { key: 'annex1UrlList', title: '证明材料' },
                ], value: [], title: '【担任外单位管理工作】履现职以来，若在外单位担任过科任教学、班主任工作之外的其他管理岗位工作，请提供具体资料，若在外单位担任过管理工作，需原单位提供组织机构沿革表。', bgTit: '主要工作经历和管理工作', bgShow: false
            },
            {
                topCategory: 1, secondCategory: 15, key: '', tsList: [
                    { key: 'name', title: '岗位名称' },
                    { key: 'timeList', title: '起止时间' },
                    { key: 'content', title: '工作内容' },
                    { key: 'remarks', title: '备注' },
                    { key: 'annex1UrlList', title: '证明材料' },
                ], value: [], title: '【担任本校管理工作】履现职以来，若在本校担任过科任教学、班主任工作之外的其他管理岗位工作，请提供具体资料。', bgTit: '主要工作经历和管理工作', bgShow: false
            },
            {
                topCategory: 1, secondCategory: 16, key: '', tsList: [
                    { key: 'orgName', title: '兼职单位名称' },
                    { key: 'name', title: '岗位名称' },
                    { key: 'timeList', title: '起止时间' },
                    { key: 'content', title: '工作内容' },
                    { key: 'remarks', title: '备注' },
                    { key: 'annex1UrlList', title: '证明材料' },
                ], value: [], title: '【担任外单位兼职工作】履现职以来，若在外单位担任兼职工作，请提供具体资料', bgTit: '主要工作经历和管理工作', bgShow: false
            },
            {
                topCategory: 1, secondCategory: 17, key: '', tsList: [
                    { key: 'name', title: '岗位名称' },
                    { key: 'timeList', title: '起止时间' },
                    { key: 'content', title: '工作内容' },
                    { key: 'remarks', title: '备注' },
                    { key: 'annex1UrlList', title: '证明材料' },
                ], value: [], title: '【担任本校兼职工作】履现职以来，若在本校担任兼职工作，请提供具体资料', bgTit: '主要工作经历和管理工作', bgShow: false
            }
        ]
    },
    {
        smTit: '第二部分:教师基础信息',
        smId: 'part2',
        smList: [
            {
                topCategory: 2, secondCategory: 1, key: '', tsList: [
                    { key: 'typeName', title: '骨干教师类型', optionName: 'core_teacher_type' },
                    { key: 'startTime', title: '获得称号时间' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '请对应您所获得的每种类型的骨干教师称号，添加具体资料', bgTit: '骨干教师信息', bgShow: true
            },
        ]
    },
    {
        smTit: '第三部分:德育工作',
        smId: 'part3',
        smList: [
            {
                topCategory: 3, secondCategory: 1, key: '', tsList: [
                    { key: 'typeName', title: '德育称号', optionName: 'moral_edu_type' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'startTime', title: '获得称号时间' },
                    { key: 'relationOrgName', title: '颁发部门', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '【德育称号】参加工作以来，若有获得德育称号，请对应每种类型的称号，添加具体资料', bgTit: '德育工作', bgShow: true
            },
            {
                topCategory: 3, secondCategory: 2, key: '', tsList: [
                    { key: 'typeName', title: '少先队称号', optionName: 'young_pioneers_type' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'startTime', title: '获得称号时间' },
                    { key: 'relationOrgName', title: '颁发机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '【少先队称号】参加工作以来，若有获得少先队称号，请对应每种类型的称号，添加具体资料', bgTit: '德育工作', bgShow: false
            },
            {
                topCategory: 3, secondCategory: 3, key: '', tsList: [
                    { key: 'name', title: '班主任/副班主任', optionName: 'bzrType' },
                    { key: 'schoolArea', title: '校区', optionName: 'school_area' },
                    { key: 'grade', title: '年级' },
                    { key: 'classInfo', title: '班级' },
                    { key: 'typeName', title: '荣誉称号', optionName: 'class_honorary_title' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'startTime', title: '获奖时间' },
                    { key: 'relationOrgName', title: '颁发机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '【品质班集体相关荣誉】近5学年，若担任班主任/副班主任时，所带班级曾被评为品质班级或先进班集体班级，请提供具体资料。【班主任、副班主任所填报的荣营需在任期内】(含区级、市级、省级、国家级)', bgTit: '德育工作', bgShow: false
            },
            {
                topCategory: 3, secondCategory: 4, key: '', tsList: [
                    { key: 'typeName', title: '活动类型', placeholder: '如主题班会、主题队会、主题教育实践活动、社会实践活动等' },
                    { key: 'name', title: '活动名称' },
                    { key: 'address', title: '活动地点' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'startTime', title: '时间' },
                    { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证明材料' },
                ], value: [], title: '【育人活动】近 5 年内，若有参与示范展示类育人活动(含校级、区级、市级、省级、国家级)，请添加具体资料【代表校区/学校进行展示的活动】', bgTit: '德育工作', bgShow: false
            },
            {
                topCategory: 3, secondCategory: 5, key: '', tsList: [
                    { key: 'name', title: '活动名称' },
                    { key: 'typeName', title: '分享主题/名称' },
                    { key: 'address', title: '活动地点' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'startTime', title: '时间' },
                    { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证明材料' },
                ], value: [], title: '【育人经验工作分享】近 5 年内，若有承担各级育人经验工作分享(含校级、区级、市级、省级、国家级)', bgTit: '德育工作', bgShow: false
            },
            {
                topCategory: 3, secondCategory: 6, key: 'isSelect', value: '', noShowLabel: true, optionList: ['符合', '不符合'], title: '遵守师德规范，提高道德修养，勤勉敬业，乐于奉献，廉洁从教，树立良好的师德形象。', bgTit: '师德师风', bgShow: true
            },
            {
                topCategory: 3, secondCategory: 7, key: '', tsList: [
                    { key: 'timeList', title: '起止时间' },
                ], value: [], title: '参加工作以来担任班主任工作情况', bgTit: '班主任工作', bgShow: true
            },
            {
                topCategory: 3, secondCategory: 8, key: '', tsList: [
                    { key: 'schoolYear', title: '学年' },
                    { key: 'schoolTerm', title: '学期' },
                    { key: 'schoolArea', title: '校区', optionName: 'school_area' },
                    { key: 'grade', title: '年级' },
                    { key: 'classInfo', title: '班级' },
                    { key: 'remarks', title: '备注' },
                ], value: [], title: '近 5 学年担任班主任具体情况', bgTit: '班主任工作', bgShow: false
            },
            {
                topCategory: 3, secondCategory: 9, key: '', tsList: [
                    { key: 'timeList', title: '起止时间' },
                ], value: [], title: '参加工作以来担任副班主任工作情况', bgTit: '副班主任工作', bgShow: true
            },
            {
                topCategory: 3, secondCategory: 10, key: '', tsList: [
                    { key: 'schoolYear', title: '学年' },
                    { key: 'schoolTerm', title: '学期' },
                    { key: 'schoolArea', title: '校区', optionName: 'school_area' },
                    { key: 'grade', title: '年级' },
                    { key: 'classInfo', title: '班级' },
                    { key: 'remarks', title: '备注' },
                ], value: [], title: '近 5 学年担任副班主任具体情况', bgTit: '副班主任工作', bgShow: false
            },
            {
                topCategory: 3, secondCategory: 11, key: '', tsList: [
                    { key: 'timeList', title: '起止时间' },
                ], value: [], title: '参加工作以来担任辅导员工作情况', bgTit: '辅导员工作', bgShow: true
            },
            {
                topCategory: 3, secondCategory: 12, key: '', tsList: [
                    { key: 'schoolYear', title: '学年' },
                    { key: 'schoolTerm', title: '学期' },
                    { key: 'schoolArea', title: '校区', optionName: 'school_area' },
                    { key: 'grade', title: '年级' },
                    { key: 'classInfo', title: '班级' },
                    { key: 'remarks', title: '备注' },
                ], value: [], title: '近 5 学年担任辅导员工作具体情况', bgTit: '辅导员工作', bgShow: false
            },
        ]
    },
    {
        smTit: '第四部分:教学工作',
        smId: 'part4',
        smList: [
            {
                topCategory: 4, secondCategory: 1, key: '', tsList: [
                    { key: 'schoolArea', title: '校区', optionName: 'school_area' },
                    { key: 'grade', title: '年级' },
                    { key: 'classInfo', title: '班级' },
                    { key: 'periodSubjectList', title: '学科' },
                    { key: 'timeList', title: '起止时间' },
                    { key: 'isSelect', title: '是否担任兴趣班课程' },
                ], value: [], title: '当前所教年级、班级和学科', bgTit: '教学基本信息', bgShow: true
            },
            {
                topCategory: 4, secondCategory: 2, key: '', tsList: [
                    { key: 'name', title: '课例名称' },
                    { key: 'categoryName', title: '内容/主题类别', optionName: 'content_theme_type' },
                    { key: 'relationName', title: '所属活动名称' },
                    { key: 'typeName', title: '类型', optionName: 'class_teach_type' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'prizeLevel', title: '获奖等级', optionName: 'award_level' },
                    { key: 'startTime', title: '获奖时间' },
                    { key: 'relationOrgName', title: '颁发机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '获奖证书' },
                ], value: [], title: '【课堂教学获奖】参加工作以来，若有课堂教学(含录像课例、现场课例、微课、说课、教学技能)获奖(含校级、区级、市级、省级、国家级)', bgTit: '教学工作获奖', bgShow: true
            },
            {
                topCategory: 4, secondCategory: 3, key: '', tsList: [
                    { key: 'name', title: '活动名称' },
                    { key: 'categoryName', title: '内容/主题类别', optionName: 'content_theme_type' },
                    { key: 'relationName', title: '学生姓名', placeholder: '可填多个学生姓名，分号分隔' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'prizeLevel', title: '获奖等级', optionName: 'award_level' },
                    { key: 'startTime', title: '时间' },
                    { key: 'relationOrgName', title: '颁发机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '获奖证书' },
                ], value: [], title: '【指导学生获奖】近 5 年内，若指导学生在活动(仅包括教育局统一发文、学校统一组织的活动)中获奖(含校级、区级、市级、省级、国家级)', bgTit: '教学工作获奖', bgShow: false
            },
            {
                topCategory: 4, secondCategory: 4, key: '', tsList: [
                    { key: 'name', title: '荣誉名称' },
                    { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'prizeLevel', title: '获奖等级', optionName: 'award_level' },
                    { key: 'startTime', title: '时间' },
                    { key: 'relationOrgName', title: '颁发机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '获奖证书' },
                ], value: [], title: '【其他教学工作获奖】参加工作以来，若在教学工作方面有上述未提及的重要荣誉奖项(含校级、区级、市级、省级、国家级)', bgTit: '教学工作获奖', bgShow: false
            },
            {
                topCategory: 4, secondCategory: 5, key: '', tsList: [
                    { key: 'isSelect', title: '研究课/示范课', optionList: ['研究课', '示范课'] },
                    { key: 'name', title: '课例名称' },
                    { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                    { key: 'relationName', title: '所属活动名称' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'startTime', title: '时间' },
                    { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '【主讲研究课】近5年内，若主讲过研究课/示范课，请添加具体资料:(含校级、区级、市级、省级、国家级)', bgTit: '教学工作获奖', bgShow: false
            },
            {
                topCategory: 4, secondCategory: 6, key: '', tsList: [
                    { key: 'name', title: '研讨会名称' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'startTime', title: '时间' },
                    { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '【教育教学、学校管理思想研讨会】近5年内，若组织过教育教学、学校管理思想研讨会，请提供具体资料(含校级、区级、市级、省级、国家级)', bgTit: '教学工作获奖', bgShow: false
            },
            {
                topCategory: 4, secondCategory: 7, key: '', tsList: [
                    { key: 'name', title: '研讨名称' },
                    { key: 'relationName', title: '所属活动名称' },
                    { key: 'startTime', title: '时间' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '【主讲教学研讨和交流】近 5 年内，若主讲过教学研讨和交流(含校级、区级、市级、省级、国家级)', bgTit: '教学工作获奖', bgShow: false
            },
            {
                topCategory: 4, secondCategory: 8, key: '', tsList: [
                    { key: 'address', title: '地点' },
                    { key: 'periodSubjectList', title: '上课科目' },
                    { key: 'timeList', title: '起止时间' },
                    { key: 'relationName', title: '所属活动名称' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证明材料' },
                ], value: [], title: '【送教下乡】近 5 年内，若有过送教下乡，请添加具体资料', bgTit: '教学工作获奖', bgShow: false
            },
            {
                topCategory: 4, secondCategory: 9, key: '', tsList: [
                    { key: 'name', title: '支教名称' },
                    { key: 'levelName', title: '级别', optionName: 'teach_support_level' },
                    { key: 'startTime', title: '支教起始时间' },
                    { key: 'schoolTerm', title: '支教总学期数', isNum: true },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证明材料' },
                ], value: [], title: '近5年内，若有外出支教、且单次支教时间达到或超过一学期(含区内支教、区外市内支教、市外省内支教、省外支教)，请添加具体资料', bgTit: '外出支教', bgShow: true
            },
            {
                topCategory: 4, secondCategory: 10, key: '', tsList: [
                    { key: 'schoolYear', title: '年份' },
                    { key: 'typeName', title: '履行情况', optionList: ['合格', '良好', '优秀'] },
                ], value: [], title: '请埴写近 5 年来的履职考核结果', bgTit: '五年履职考核', bgShow: true
            },
            {
                topCategory: 4, secondCategory: 11, key: '', tsList: [
                    { key: 'schoolYear', title: '年份' },
                    { key: 'classInfo', title: '平均课时' },
                ], value: [], title: '请埴写近 5 年来的平均课量', bgTit: '五年学科任课量', bgShow: true
            },
        ]
    },
    {
        smTit: '第五部分:指导青年教师',
        smId: 'part5',
        smList: [
            {
                topCategory: 5, secondCategory: 1, key: '', tsList: [
                    { key: 'typeName', title: '帮扶性质', optionName: 'assistance_nature' },
                    { key: 'num', title: '指导人数' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '近 5 年内，若有常规指导青年教师(含校内、区内、区外帮扶师徒结对)', bgTit: '指导青年教师', bgShow: true
            },
            {
                topCategory: 5, secondCategory: 2, key: '', tsList: [
                    { key: 'relationName', title: '被指导教师' },
                    { key: 'startTime', title: '指导起始时间' },
                    { key: 'typeName', title: '教学成绩趋势', optionList: ['上升', '持平', '下降'] },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '成绩变化证明' },
                ], value: [], title: '请填报近 5 学年内常规指导的青年教师，其教学成绩变化的具体情况(3 名及以下需全部填报，3 名以上可选填)，证明材料需体现成绩排名变化情况，由教务处加盖公章', bgTit: '指导青年教师', bgShow: false
            },
            {
                topCategory: 5, secondCategory: 3, key: '', tsList: [
                    { key: 'name', title: '活动名称' },
                    { key: 'relationName', title: '被指导教师' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'prizeLevel', title: '获奖等级', optionName: 'award_level' },
                    { key: 'startTime', title: '时间' },
                    { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '获奖证书' },
                ], value: [], title: '近 5 年内，若有指导青年教师在各级教育教学评比活动中获奖(含校级、区级、市级、省级、国家级)，请添加具体资料(指导的每位青年教师仅需选取最高的三个奖项填写即可)', bgTit: '指导青年教师', bgShow: false
            },
            {
                topCategory: 5, secondCategory: 4, key: '', tsList: [
                    { key: 'name', title: '活动名称' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'startTime', title: '时间' },
                    { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '【担任评市专家】近5年内，若担任过教育教学评比活动的评市专家，请添加具体资料(含校级、区级、市级、省级、国家级)', bgTit: '指导青年教师', bgShow: false
            },
            {
                topCategory: 5, secondCategory: 5, key: '', tsList: [
                    { key: 'name', title: '工作室名称' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'startTime', title: '组建时间' },
                    { key: 'num', title: '成员数量' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '【名师工作室】近5年内，若有主持名师工作室/名校校长工作室，请添加具体资料(含区级、市级、省级、国家级)', bgTit: '指导青年教师', bgShow: false
            },
        ]
    },
    {
        smTit: '第六部分:科研工作',
        smId: 'part6',
        smList: [
            {
                topCategory: 6, secondCategory: 1, key: '', tsList: [
                    { key: 'name', title: '课题名称' },
                    { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                    { key: 'startTime', title: '开题时间' },
                    { key: 'statusName', title: '课题状态', optionName: 'archive_topic_status' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'relationOrgName', title: '认定机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '【主持课题研究】参加工作以来，若有主持课题研究，请添加具体资料(含校级、区级、市级、省级、国家级)', bgTit: '科研工作', bgShow: true
            },
            {
                topCategory: 6, secondCategory: 2, key: '', tsList: [
                    { key: 'name', title: '课题名称' },
                    { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                    { key: 'startTime', title: '开题时间' },
                    { key: 'statusName', title: '课题状态', optionName: 'archive_topic_status' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'relationOrgName', title: '认定机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '【叁与课题研究】参加工作以来，若有参与课题研究，请添加具体资料 (含校级、区级、市级、省级、国家级)', bgTit: '科研工作', bgShow: false
            },
            {
                topCategory: 6, secondCategory: 3, key: '', tsList: [
                    { key: 'typeName', title: '成果类型', optionName: 'archive_achieve_type' },
                    { key: 'isSelect', title: '是否课题类成果' },
                    { key: 'name', title: '成果名称' },
                    { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                    { key: 'relationName', title: '承担角色', optionName: 'assume_role' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'prizeLevel', title: '获奖等级', optionName: 'award_level' },
                    { key: 'startTime', title: '获奖时间' },
                    { key: 'relationOrgName', title: '认定机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '【论文成里获奖】参加工作以来，若有论文、教学案例、育人案例、教学课例成果获奖，请提供具体资料(含校级、区级、市级、省级、国家级)', bgTit: '科研工作', bgShow: false
            },
            {
                topCategory: 6, secondCategory: 4, key: '', tsList: [
                    { key: 'name', title: '成果名称' },
                    { key: 'isSelect', title: '是否课题类成果' },
                    { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                    { key: 'relationName', title: '承担角色', optionName: 'assume_role' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'prizeLevel', title: '获奖等级', optionName: 'award_level' },
                    { key: 'startTime', title: '获奖时间' },
                    { key: 'relationOrgName', title: '认定机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '【教育教学成果获奖】参加工作以来，若有教育教学成果获奖，请提供具体资料', bgTit: '科研工作', bgShow: false
            },
            {
                topCategory: 6, secondCategory: 5, key: '', tsList: [
                    { key: 'name', title: '论著名称' },
                    { key: 'isSelect', title: '是否课题类成果' },
                    { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                    { key: 'typeName', title: '论著类别', optionName: 'opus_type' },
                    { key: 'relationName', title: '编著身份', optionName: 'opus_role' },
                    { key: 'startTime', title: '出版时间' },
                    { key: 'publisher', title: '出版社' },
                    { key: 'publishNo', title: '版号' },
                    { key: 'annex1UrlList', title: '论著封面、目录页、姓名页' },
                ], value: [], title: '参加工作以来，若有出版论著，请添加具体资料', bgTit: '科研工作', bgShow: false
            },
            {
                topCategory: 6, secondCategory: 6, key: '', tsList: [
                    { key: 'name', title: '作品名称' },
                    { key: 'isSelect', title: '是否课题类成果' },
                    { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'startTime', title: '发表时间' },
                    { key: 'publishType', title: '期刊性质', optionName: 'archive_area_level' },
                    { key: 'publishNo', title: '期刊刊号' },
                    { key: 'publisher', title: '期刊名称' },
                    { key: 'annex1UrlList', title: '发表复印件' },
                ], value: [], title: '【论文成果获奖】参加工作以来，若有论文、教学案例、育人案例、教学课例成果获奖，请提供具体资料(含校级、区级、市级、省级、国家级)', bgTit: '科研工作', bgShow: false
            },
        ]
    },
    {
        smTit: '第七部分:学习提高',
        smId: 'part7',
        smList: [
            {
                topCategory: 7, secondCategory: 1, key: '', tsList: [
                    { key: 'name', title: '培训名称' },
                    { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'startTime', title: '时间' },
                    { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '近5年内，若有承担各级培训任务，请添加具体资料(含校级、区级、市级、省级、国家级)', bgTit: '继续教育', bgShow: true
            },
            {
                topCategory: 7, secondCategory: 2, key: '', tsList: [
                    { key: 'schoolYear', title: '学年度' },
                    { key: 'annex1UrlList', title: '学年度学时单', accept: '.pdf' },
                ], value: [], title: '近5学年，若有参加各级研修，请上传学时网的学时单', bgTit: '继续教育', bgShow: false
            },
            {
                topCategory: 7, secondCategory: 3, key: '', tsList: [
                    { key: 'name', title: '研修名称' },
                    { key: 'categoryName', title: '内容类别', optionName: 'content_theme_type' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'startTime', title: '时间' },
                    { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '近 5 年内，若有参加各级校外研修(含区级、市级、省级、国家级)，请添加具体资料(依据个人学时单填写)', bgTit: '校外研修', bgShow: true
            },
            {
                topCategory: 7, secondCategory: 4, key: '', tsList: [
                    { key: 'isSelect', title: '来源', optionList: ['校本培训', '校本研修'] },
                    { key: 'annex1UrlList', title: '证明材料(合格证)' },
                ], value: [], title: '本学年若参与了校本研修或培训，请添加具体资料(依据个人学时单填写)', bgTit: '校本研修', bgShow: true
            },
        ]
    },
    {
        smTit: '第八部分:其他荣誉类称号',
        smId: 'part8',
        smList: [
            {
                topCategory: 8, secondCategory: 1, key: '', tsList: [
                    { key: 'name', title: '称号名称' },
                    { key: 'levelName', title: '级别', optionName: 'archive_area_level' },
                    { key: 'startTime', title: '时间' },
                    { key: 'relationOrgName', title: '组织机构', optionName: 'award_org' },
                    { key: 'orgName', title: '证明材料签章单位' },
                    { key: 'annex1UrlList', title: '证书' },
                ], value: [], title: '请对应您所获得的每种类型的其他荣誉类称号，添加具体资料', bgTit: '其他荣誉类称号', bgShow: true
            },
        ]
    },
]

export const dtArea = {
    
}