/*路由配置*/
import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
import UserLogin from "@/views/public/userLogin";
import CodeLogin from "@/views/public/codeLogin"
import ResetPwd from "@/views/public/resetPwd"
import Register from "@/views/public/register"
import GroupHome from "@/views/groupHome"
import Policy from "@/views/public/policy"
import WorkBook from "@/views/public/workBook"
import InviteMember from "@/views/public/inviteMember"

import ArrayMaintenance from "@/views/arrayMaintenance/copytext"

import GroupSet from "@/views/groupSet/index"

import GroupSetAdd from "@/views/groupSet/groupSetAdd"

import editor from "@/views/editor"

import news from "@/views/news/index"
import newsAdd from "@/views/news/newsAdd"
import newsDetail from "@/views/news/newsDetail"

import classLibrary from "@/views/classLibrary/index"
import classLibraryAdd from "@/views/classLibrary/classLibraryAdd"
import classLibraryDetail from "@/views/classLibrary/classLibraryDetail"

import banner from "@/views/banner/index"
import bannerAdd from "@/views/banner/bannerAdd"
import bannerDetail from "@/views/banner/bannerDetail"

import bannerNotice from "@/views/bannerNotice/index"
import bannerNoticeAdd from "@/views/bannerNotice/bannerNoticeAdd"
import bannerNoticeDetail from "@/views/bannerNotice/bannerNoticeDetail"

import lesson from "@/views/lesson/index"
import lessonAdd from "@/views/lesson/lessonAdd"
import lessonDetail from "@/views/lesson/lessonDetail"
import lessonShare from "@/views/lesson/lessonShare"
import lessonFileDetail from "@/views/lesson/lessonFileDetail"
import lessonFileGADetail from "@/views/lesson/lessonFileGADetail"
import lessonDiscuss from "@/views/lesson/lessonDiscuss"
import myLess from "@/views/lesson/myLess"
import shareApply from "@/views/lesson/shareApply"

import evaluation from "@/views/evaluation/index"
import evaluationAdd from "@/views/evaluation/evaluationAdd"
import evaluationDetail from "@/views/evaluation/evaluationDetail"

import rateScale from "@/views/evaluation/rateScale"

import train from "@/views/train/index"
import trainAdd from "@/views/train/trainAdd"
import trainDetail from "@/views/train/trainDetail"
import trainCourseLookVideo from "@/views/train/trainCourseLookVideo"

import videoLibrary from "@/views/train/videoLibrary"
import videoLibraryAdd from "@/views/train/videoLibraryAdd"

import lead from "@/views/lead/index"
import leadAdd from "@/views/lead/leadAdd"
import leadDetail from "@/views/lead/leadDetail"
import topicApply from "@/views/topicApply/index"
import topicApplyDetail from "@/views/topicApply/topicApplyDetail"
import activitApplyDetail from "@/views/topicApply/activitApplyDetail"

import race from "@/views/race/index"
import raceAdd from "@/views/race/raceAdd"
import raceDetail from "@/views/race/raceDetail"

import activity from "@/views/activity/index"
import activityAdd from "@/views/activity/activityAdd"
import activityDetail from "@/views/activity/activityDetail"

import handleApply from "@/views/handleApply/index"
import handleApplyDetail from "@/views/handleApply/handleApplyDetail"

import famousCourse from "@/views/famousCourse/index"
import famousCourseAdd from "@/views/famousCourse/famousCourseAdd"
import famousCourseDetail from "@/views/famousCourse/famousCourseDetail"
import famousCourseUploadVideo from "@/views/famousCourse/famousCourseUploadVideo"
import famousList from "@/views/famousCourse/famousList"
import famousCourseLookVideo from "@/views/famousCourse/famousCourseLookVideo"

import courseware from "@/views/courseware/index"
import coursewareAdd from "@/views/courseware/coursewareAdd"
import coursewareDetail from "@/views/courseware/coursewareDetail"
import coursewareUploadVideo from "@/views/courseware/coursewareUploadVideo"
import coursewareList from "@/views/courseware/coursewareList"
import coursewareLookVideo from "@/views/courseware/coursewareLookVideo"
import flash from "@/views/courseware/flash"


import traCourse from "@/views/traCourse/index"
import traCourseAdd from "@/views/traCourse/traCourseAdd"
import traCourseDetail from "@/views/traCourse/traCourseDetail"
import traCourseUploadVideo from "@/views/traCourse/traCourseUploadVideo"
import traCourseList from "@/views/traCourse/traCourseList"

import teachResearch from "@/views/teachResearch/index"
import teachResearchAdd from "@/views/teachResearch/teachResearchAdd"
import teachResearchDetail from "@/views/teachResearch/teachResearchDetail"

import achieve from "@/views/achieve/index"
import achieveAdd from "@/views/achieve/achieveAdd"
import achieveDetail from "@/views/achieve/achieveDetail"

import studioList from "@/views/famousPrincipal/studioList"
import studioManage from "@/views/famousPrincipal/studioManage"

import workBrieManage from "@/views/famousPrincipal/workBrieManage"

import teachStudioList from "@/views/famousPrincipal/teachStudioList"
import teachStudioManage from "@/views/famousPrincipal/teachStudioManage"

import studioAdd from "@/views/famousPrincipal/studioAdd"
import studioDetail from "@/views/famousPrincipal/studioDetail"
import studioRoomDetail from "@/views/famousPrincipal/studioRoomDetail"

import messageCenter from "@/views/messageCenter/index"
import systemCenter from "@/views/messageCenter/systemCenter"

import collectionCenter from "@/views/collectionCenter/index"

import myFollow from "@/views/myFollow/index"

import userInfo from "@/views/personApp/userInfo"

import uesrManage from "@/views/uesrManage/index"
import uesrAdd from "@/views/uesrManage/uesrAdd"

import ptStatistics from "@/views/statistics/ptStatistics"

import jkyStatistics from "@/views/statistics/jkyStatistics"

import szpxStatistics from "@/views/statistics/szpxStatistics"

import msgzsStatistics from "@/views/statistics/msgzsStatistics"

import principalStudio from "@/views/statistics/principalStudio"

import homePage from "@/views/public/homePage"

import fileFill from "@/views/teacherFile/fileFill"
import fileAllocation from "@/views/teacherFile/fileAllocation"
import fileManage from "@/views/teacherFile/fileManage"
import fileStatistics from "@/views/teacherFile/fileStatistics"
import checkList from "@/views/teacherFile/checkList"

import indexs from "@/views/index.vue"

const routes = [
    { path: '/', redirect: '/login' },
    { path: '/login', name: '用户账号登录', meta: { title: '用户账号登录' }, component: UserLogin },
    { path: '/inviteMember', name: '场景邀请成员', meta: { title: '纸笔智能服务平台' }, component: InviteMember },
    { path: '/codeLogin', name: '用户验证码登录', meta: { title: '用户验证码登录' }, component: CodeLogin },
    { path: '/register', name: '用户注册', meta: { title: '用户注册' }, component: Register },
    { path: '/resetPwd', name: '用户重置密码', meta: { title: '用户重置密码' }, component: ResetPwd },
    { path: '/policy', name: '云岗教研隐私政策', meta: { title: '云岗教研隐私政策' }, component: Policy },
    { path: '/workBook', name: '群笔记操作手册', meta: { title: '群笔记操作手册' }, component: WorkBook },
    { path: '/editor', name: '編輯器', meta: { title: '編輯器' }, component: editor },

    { path: '/lessonFileDetail', name: '协同备课-查看文件', meta: { title: '查看文件' }, component: lessonFileDetail },
    { path: '/lessonFileGADetail', name: '协同备课-查看', meta: { title: '协同备课-查看' }, component: lessonFileGADetail },
    { path: '/lessonDiscuss', name: '协同备课-去研讨', meta: { title: '协同备课-去研讨' }, component: lessonDiscuss },
    { path: "/lookflash", name: "互动元件", meta: { title: '互动元件' }, component: flash },

    { path: '/fileFill', name: '档案填报', meta: { title: '档案填报' }, component: fileFill },
    { path: '/fileFillck', name: '档案查看', meta: { title: '档案查看' }, component: fileFill },
    { path: '/fileFillsh', name: '档案审核', meta: { title: '档案审核' }, component: fileFill },
    { path: '/fileAllocation', name: '档案分配', meta: { title: '档案分配' }, component: fileAllocation },
    { path: '/fileManage', name: '档案管理', meta: { title: '档案管理' }, component: fileManage },
    { path: '/fileStatistics', name: '驾驶舱', meta: { title: '驾驶舱' }, component: fileStatistics },
    { path: '/checkList', name: '档案审核', meta: { title: '档案审核' }, component: checkList },

    { path: '/homePage', name: '孜博汇-主页', meta: { title: '主页' }, component: homePage },

    {
        path: '/home',
        name: "首页",
        component: GroupHome,
        redirect: "/indexs",
        meta: { title: '首页-王后雄管理中心' },
        children: [
            {
                path: '/indexs',
                component: indexs,
                meta: { title: '首页' },
            },

            { path: "/arrayMaintenance", name: "arrayMaintenance", meta: { title: '组织结构', keepAlive: true }, component: ArrayMaintenance },

            { path: "/groupSet", name: "groupSet", meta: { title: '群组维护', keepAlive: true }, component: GroupSet },

            { path: "/groupSetAdd", name: "groupSetAdd", meta: { title: '新增群组', hidden: true }, component: GroupSetAdd },

            { path: "/news", name: "news", meta: { title: '新闻资讯管理', keepAlive: true }, component: news },
            { path: "/newsAdd", name: "newsAdd", meta: { title: '新增新闻资讯', hidden: true }, component: newsAdd },
            { path: "/newsDetail", name: "newsDetail", meta: { title: '新闻详情', hidden: true }, component: newsDetail },

            { path: "/classLibrary", name: "classLibrary", meta: { title: '教程库管理', keepAlive: true }, component: classLibrary },
            { path: "/classLibraryAdd", name: "classLibraryAdd", meta: { title: '新增课本', hidden: true }, component: classLibraryAdd },
            { path: "/classLibraryDetail", name: "classLibraryDetail", meta: { title: '课本详情', hidden: true }, component: classLibraryDetail },

            { path: "/banner", name: "banner", meta: { title: 'banner管理', keepAlive: true }, component: banner },
            { path: "/bannerAdd", name: "bannerAdd", meta: { title: '新增banner', hidden: true }, component: bannerAdd },
            { path: "/bannerDetail", name: "bannerDetail", meta: { title: 'banner详情', hidden: true }, component: bannerDetail },

            { path: "/bannerNotice", name: "bannerNotice", meta: { title: '通知banner管理', keepAlive: true }, component: bannerNotice },
            { path: "/bannerNoticeAdd", name: "bannerNoticeAdd", meta: { title: '新增通知banner', hidden: true }, component: bannerNoticeAdd },
            { path: "/bannerNoticeDetail", name: "bannerNoticeDetail", meta: { title: '通知banner详情', hidden: true }, component: bannerNoticeDetail },

            { path: "/lesson", name: "lesson", meta: { title: '协同备课', keepAlive: true }, component: lesson },
            { path: "/lessonAdd", name: "lessonAdd", meta: { title: '新增协同备课', hidden: true }, component: lessonAdd },
            { path: "/lessonDetail", name: "lessonDetail", meta: { title: '协同备课详情', hidden: true }, component: lessonDetail },
            { path: "/lessttShare", name: "lessonShare", meta: { title: '已完备课', }, component: lessonShare },

            { path: "/myLess", name: "myLess", meta: { title: '个人备课', keepAlive: true }, component: myLess },
            { path: "/shareApply", name: "shareApply", meta: { title: '备课审核', keepAlive: true }, component: shareApply },

            { path: "/evaluation", name: "evaluation", meta: { title: '听评教研', keepAlive: true }, component: evaluation },
            { path: "/evaluationAdd", name: "evaluationAdd", meta: { title: '新增听评教研', hidden: true }, component: evaluationAdd },
            { path: "/evaluationDetail", name: "evaluationDetail", meta: { title: '听评教研详情', hidden: true }, component: evaluationDetail },

            { path: "/rateScale", name: "rateScale", meta: { title: '听评课量表', keepAlive: true }, component: rateScale },

            { path: "/train", name: "train", meta: { title: '师资培训', keepAlive: true }, component: train },
            { path: "/trainAdd", name: "trainAdd", meta: { title: '新增师资培训', hidden: true }, component: trainAdd },
            { path: "/trainDetail", name: "trainDetail", meta: { title: '师资培训详情', hidden: true }, component: trainDetail },
            { path: "/trainCourseLookVideo", name: "trainCourseLookVideo", meta: { title: '师资培训查看视频', hidden: true }, component: trainCourseLookVideo },

            { path: "/videoLibrary", name: "videoLibrary", meta: { title: '培训视频库', keepAlive: true }, component: videoLibrary },
            { path: "/videoLibraryAdd", name: "videoLibraryAdd", meta: { title: '新增培训视频', hidden: true }, component: videoLibraryAdd },

            { path: "/lead", name: "lead", meta: { title: '课题引领', keepAlive: true }, component: lead },
            { path: "/leadAdd", name: "leadAdd", meta: { title: '新增课题引领', hidden: true }, component: leadAdd },
            { path: "/leadDetail", name: "leadDetail", meta: { title: '课题引领详情', hidden: true }, component: leadDetail },

            { path: "/topicApply", name: "topicApply", meta: { title: '申报审核', keepAlive: true }, component: topicApply },
            { path: "/topicApplyDetail", name: "topicApplyDetail", meta: { title: '审核详情', hidden: true }, component: topicApplyDetail },
            { path: "/activitApplyDetail", name: "activitApplyDetail", meta: { title: '审核详情', hidden: true }, component: activitApplyDetail },

            { path: "/race", name: "race", meta: { title: '教研赛事', keepAlive: true }, component: race },
            { path: "/raceAdd", name: "raceAdd", meta: { title: '新增教研赛事', hidden: true }, component: raceAdd },
            { path: "/raceDetail", name: "raceDetail", meta: { title: '教研赛事详情', hidden: true }, component: raceDetail },

            { path: "/activity", name: "activity", meta: { title: '专题活动', keepAlive: true }, component: activity },
            { path: "/activityAdd", name: "activityAdd", meta: { title: '新增专题活动', hidden: true }, component: activityAdd },
            { path: "/activityDetail", name: "activityDetail", meta: { title: '专题活动详情', hidden: true }, component: activityDetail },

            { path: "/studioList", name: "studioList", meta: { title: '名校长工作室列表' }, component: studioList },
            { path: "/studioManage", name: "studioManage", meta: { title: '名校长工作室管理' }, component: studioManage },
            { path: "/teachStudioList", name: "teachStudioList", meta: { title: '名师工作室列表' }, component: teachStudioList },
            { path: "/teachStudioManage", name: "teachStudioManage", meta: { title: '名师工作室管理' }, component: teachStudioManage },
            { path: "/studioAdd", name: "studioAdd", meta: { title: '工作室资讯', hidden: true }, component: studioAdd },
            { path: "/studioDetail", name: "studioDetail", meta: { title: '工作室资讯', hidden: true }, component: studioDetail },
            { path: "/studioRoomDetail", name: "studioRoomDetail", meta: { title: '工作室详情', hidden: true }, component: studioRoomDetail },
            { path: "/workBrieManage", name: "workBrieManage", meta: { title: '工作简报管理', hidden: true }, component: workBrieManage },

            { path: "/handleApply", name: "handleApply", meta: { title: '优课审核', keepAlive: true }, component: handleApply },
            { path: "/handleApplyDetail", name: "handleApplyDetail", meta: { title: '优课审核详情', hidden: true }, component: handleApplyDetail },

            { path: "/famousCourse", name: "famousCourse", meta: { title: '资源管理', keepAlive: true }, component: famousCourse },
            { path: "/famousCourseAdd", name: "famousCourseAdd", meta: { title: '新增名师优课', hidden: true }, component: famousCourseAdd },
            { path: "/famousCourseDetail", name: "famousCourseDetail", meta: { title: '名师优课详情', hidden: true }, component: famousCourseDetail },
            { path: "/famousCourseUploadVideo", name: "famousCourseUploadVideo", meta: { title: '名师优课上传视频', hidden: true }, component: famousCourseUploadVideo },
            { path: "/famousCourseLookVideo", name: "famousCourseLookVideo", meta: { title: '名师优课查看视频', hidden: true }, component: famousCourseLookVideo },
            { path: "/famousGspList", name: "famousGspList", meta: { title: '高水平示范课', keepAlive: true }, component: famousList },
            { path: "/famousQbList", name: "famousQbList", meta: { title: '区本课程', keepAlive: true }, component: famousList },
            { path: "/famousJbList", name: "famousJbList", meta: { title: '校本课程', keepAlive: true }, component: famousList },
            { path: "/famousKhList", name: "famousKhList", meta: { title: '课后服务', keepAlive: true }, component: famousList },
            { path: "/famousAIList", name: "famousAIList", meta: { title: '人工智能', keepAlive: true }, component: famousList },

            { path: "/courseware", name: "courseware", meta: { title: '课件管理', keepAlive: true }, component: courseware },
            { path: "/coursewareAdd", name: "coursewareAdd", meta: { title: '新增课件', hidden: true }, component: coursewareAdd },
            { path: "/coursewareDetail", name: "coursewareDetail", meta: { title: '课件详情', hidden: true }, component: coursewareDetail },
            { path: "/coursewareUploadVideo", name: "coursewareUploadVideo", meta: { title: '上传课件', hidden: true }, component: coursewareUploadVideo },
            { path: "/coursewareLookVideo", name: "coursewareLookVideo", meta: { title: '查看课件', hidden: true }, component: coursewareLookVideo },
            { path: "/coursewareList", name: "coursewareList", meta: { title: '互动课件', keepAlive: true }, component: coursewareList },

            { path: "/traCourse", name: "traCourse", meta: { title: '培训视频管理', keepAlive: true }, component: traCourse },
            { path: "/traCourseAdd", name: "traCourseAdd", meta: { title: '新增培训视频', hidden: true }, component: traCourseAdd },
            { path: "/traCourseDetail", name: "traCourseDetail", meta: { title: '培训视频详情', hidden: true }, component: traCourseDetail },
            { path: "/traCourseUploadVideo", name: "traCourseUploadVideo", meta: { title: '培训视频上传视频', hidden: true }, component: traCourseUploadVideo },
            { path: "/traCourseList", name: "traCourseList", meta: { title: '师资培训课程' }, component: traCourseList },

            { path: "/userInfo", name: "userInfo", meta: { title: '用户中心' }, component: userInfo },

            { path: "/teachResearch", name: "teachResearch", meta: { title: '群组管理', keepAlive: true }, component: teachResearch },
            { path: "/teachResearchAdd", name: "teachResearchAdd", meta: { title: '新增群组', hidden: true }, component: teachResearchAdd },
            { path: "/teachResearchDetail", name: "teachResearchDetail", meta: { title: '群组详情', hidden: true }, component: teachResearchDetail },

            { path: "/achieve", name: "achieve", meta: { title: '成果展示', keepAlive: true }, component: achieve },
            { path: "/achieveAdd", name: "achieveAdd", meta: { title: '新增成果展示', hidden: true }, component: achieveAdd },
            { path: "/achieveDetail", name: "achieveDetail", meta: { title: '成果展示详情', hidden: true }, component: achieveDetail },

            { path: "/systemCenter", name: "systemCenter", meta: { title: '消息中心' }, component: systemCenter },
            { path: "/messageCenter", name: "messageCenter", meta: { title: '消息中心' }, component: messageCenter },

            { path: "/collectionCenter", name: "collectionCenter", meta: { title: '收藏中心' }, component: collectionCenter },

            { path: "/myFollow", name: "myFollow", meta: { title: '我的关注' }, component: myFollow },

            { path: "/uesrManage", name: "uesrManage", meta: { title: '用户管理', keepAlive: true }, component: uesrManage },
            { path: "/uesrAdd", name: "uesrAdd", meta: { title: '用户管理' }, component: uesrAdd },

            { path: "/ptStatistics", name: "ptStatistics", meta: { title: '一体化教研统计', keepAlive: true }, component: ptStatistics },

            { path: "/jkyStatistics", name: "jkyStatistics", meta: { title: '教科研统计', keepAlive: true }, component: jkyStatistics },

            { path: "/szpxStatistics", name: "szpxStatistics", meta: { title: '师资培训统计', keepAlive: true }, component: szpxStatistics },

            { path: "/msgzsStatistics", name: "msgzsStatistics", meta: { title: '名师工作室统计', keepAlive: true }, component: msgzsStatistics },

            { path: "/principalStudio", name: "principalStudio", meta: { title: '名校长工作室统计', keepAlive: true }, component: principalStudio },


            // uesrManage

        ]
    },
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.path === '/login' ||
        to.path === '/codeLogin' ||
        to.path === '/resetPwd' ||
        to.path === '/workBook' ||
        to.path === '/register' ||
        to.path === '/editor' ||
        to.path === '/policy' ||
        to.path === '/inviteMember') return next()
    // 获取token
    const tokenStr = window.localStorage.getItem('token')
    if (!tokenStr) return next('/login')
    window.document.title = to.meta.title
    next()
})

export default router
