<template>
    <div class="app-container" style="padding-left: 0;">
        <div id="grbk" style="width: 70%;height:50vh;">
        </div>
    </div>
</template>

<script>
import { timestampToTime } from "@/utils/date";
import echarts from "echarts";

export default {
    name: "fileStatistics",
    components: {
    },
    data() {
        return {
            timestampToTime,
            // 遮罩层
            loading: true,
            // 基础：基数数据表格数据
            dataList: [
                // {
                //     did: 1,
                //     title: 'cs',
                //     values: '1222'
                // }
            ],
            grbkOption: {
                color: ['#3398DB'],
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {            // 坐标轴指示器，坐标轴触发有效
                        type: 'shadow'        // 默认为直线，可选为：'line' | 'shadow'
                    }
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: [
                    {
                        type: 'category',
                        data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                        axisTick: {
                            alignWithLabel: true
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value'
                    }
                ],
                series: [
                    {
                        name: '总数',
                        type: 'bar',
                        barMaxWidth: 30,
                        data: [10, 52, 200, 334, 390, 330, 220]
                    }
                ]
            },
        };
    },
    created() {
    },
    mounted() {
        // this.getGrbkOption()
    },
    activated() {
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        
        handleExport() {
        },
        getGrbkOption() {
            if (this.cativeType == '个人备课') {
                statsOwnLesson().then(res => {
                    this.grbkOption.xAxis[0].data = []
                    this.grbkOption.series[0].data = []
                    if (this.zxtQueryParams.orgIdList.length != 0) {
                        res.data.data = res.data.data.filter(itemA => {
                            return this.zxtQueryParams.orgIdList.indexOf(itemA.orgId) != -1;
                        })
                    }
                    res.data.data.forEach(e => {
                        this.grbkOption.xAxis[0].data.push(e.orgName)
                        this.grbkOption.series[0].data.push(e.amount)
                    })
                    this.zxtOpen = true;
                    setTimeout(() => {
                        let myChart3 = echarts.init(document.getElementById('grbk'))
                        myChart3.setOption(this.grbkOption, true)
                    });
                })
            } else if (this.cativeType == '协同备课') {
                statsTeamLesson().then(res => {
                    this.grbkOption.xAxis[0].data = []
                    this.grbkOption.series[0].data = []
                    if (this.zxtQueryParams.orgIdList.length != 0) {
                        res.data.data = res.data.data.filter(itemA => {
                            return this.zxtQueryParams.orgIdList.indexOf(itemA.orgId) != -1;
                        })
                    }
                    res.data.data.forEach(e => {
                        this.grbkOption.xAxis[0].data.push(e.orgName)
                        this.grbkOption.series[0].data.push(e.amount)
                    })
                    this.zxtOpen = true;
                    setTimeout(() => {
                        let myChart3 = echarts.init(document.getElementById('grbk'))
                        myChart3.setOption(this.grbkOption, true)
                    });
                })
            }

        },

    }
};
</script>
<style scoped>
/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
}

.zdp /deep/ .el-range-editor.el-input__wrapper {
    width: 100%;
}
</style>