<template>
  <div class="policy-content">

  </div>
</template>

<script>
export default {
  name: "policy"
}
</script>

<style scoped>
</style>
