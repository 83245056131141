/**
 * 时间戳转换为日期格式
 */
export const timestampToTime = (timestamp) => {
    // let date = new Date(timestamp) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    // let Y = date.getFullYear() + '-'
    // let M =
    //     (date.getMonth() + 1 < 10 ?
    //         '0' + (date.getMonth() + 1) :
    //         date.getMonth() + 1) + '-'
    // let D =
    //     (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
    // let h =
    //     (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
    // let m =
    //     (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
    //     ':'
    // let s =
    //     date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    /*return Y + M + D + h + m + s*/
    // return Y + M + D

    if(!timestamp){return ''}
    // 使用es6的padStart()方法来补0
	let time = new Date(timestamp)
	let year = time.getFullYear()
	const month = (time.getMonth() + 1).toString().padStart(2, '0')
	const date = (time.getDate()).toString().padStart(2, '0')
	const hours = (time.getHours()).toString().padStart(2, '0')
	const minute = (time.getMinutes()).toString().padStart(2, '0')
	const second = (time.getSeconds()).toString().padStart(2, '0')

	return year + '-' + month + '-' + date + ' ' + hours + ':' + minute
};
/**
 * 时间戳转换为日期格式
 */
 export const timestampToDate = (timestamp) => {
    // let date = new Date(timestamp) //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    // let Y = date.getFullYear() + '-'
    // let M =
    //     (date.getMonth() + 1 < 10 ?
    //         '0' + (date.getMonth() + 1) :
    //         date.getMonth() + 1) + '-'
    // let D =
    //     (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
    // let h =
    //     (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
    // let m =
    //     (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
    //     ':'
    // let s =
    //     date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
    /*return Y + M + D + h + m + s*/
    // return Y + M + D

    if(!timestamp){return ''}
    // 使用es6的padStart()方法来补0
	let time = new Date(timestamp)
	let year = time.getFullYear()
	const month = (time.getMonth() + 1).toString().padStart(2, '0')
	const date = (time.getDate()).toString().padStart(2, '0')
	const hours = (time.getHours()).toString().padStart(2, '0')
	const minute = (time.getMinutes()).toString().padStart(2, '0')
	const second = (time.getSeconds()).toString().padStart(2, '0')

	return hours + ':' + minute +":"+second
};
