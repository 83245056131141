<template>
  <div id="app">
    <!--路由占位-->
    <router-view v-if="isShow" />
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return { isShow: true }
  },
  created() {
    document.oncontextmenu = function () {
      event.returnValue = false;
    }// 或者直接返回整个事件
    document.oncontextmenu = function () {
      return false;
    }
  },
  provide() {
    return { reload: this.reload }
  },
  methods: {
    reload() {
      this.isShow = false;
      this.$nextTick(() => {
        this.isShow = true
      });
    },
  },
}
</script>

<style>
html,
body,
#app {
  width: 100%;
  height: 100%;
  margin: 0px;
}
</style>