<template>
    <div style="width: 100vw;height:100vh;background:#f2f2f2;">
        <div style="width: 1200px;height:100vh;background:#fff;margin:0 auto;position: relative;">
            <div>
                <el-select @change="changeSchool" v-model="orgId" placeholder="请选择学校" size="large" style="width: 240px">
                    <el-option v-for="item in schoolList" :key="item.orgId" :label="item.orgName" :value="item.orgId" />
                </el-select>
            </div>
            <div class="el-transfer">
                <div class="el-transfer-panel" style="width:533px;">
                    <div style="text-align: center;font-size:20px;line-height:50px">选择已提交审核老师</div>
                    <div class="el-transfer-panel__body" style="border-top: 1px solid #ebeef5;height: calc(100vh - 190px);">
                        <div>
                            <el-row>
                                <el-col :span="6" class="mb0">
                                    <div class="ml5 mr5 mt5">
                                        <div class="mb5">学段：</div>
                                        <el-select clearable @change="changePeriod" v-model="queryParams.periodKey"
                                            placeholder="请选择学科">
                                            <el-option v-for="item in periodList" :key="item.dictKey"
                                                :label="item.dictValue" :value="item.dictKey" />
                                        </el-select>
                                    </div>
                                </el-col>
                                <el-col :span="6" class="mb0">
                                    <div class="ml5 mr5 mt5">
                                        <div class="mb5">年级：</div>
                                        <el-select clearable @change="changeGrade" v-model="queryParams.gradeKey"
                                            placeholder="请选择年级">
                                            <el-option v-for="item in gradeList" :key="item.dictKey" :label="item.dictValue"
                                                :value="item.dictKey" />
                                        </el-select>
                                    </div>
                                </el-col>
                                <el-col :span="6" class="mb0">
                                    <div class="ml5 mr5 mt5">
                                        <div class="mb5">学科：</div>
                                        <el-select clearable v-model="queryParams.subjectKey" placeholder="请选择学科">
                                            <el-option v-for="item in subjectList" :key="item.dictKey"
                                                :label="item.dictValue" :value="item.dictKey" />
                                        </el-select>
                                    </div>
                                </el-col>
                                <el-col :span="6" class="mb0">
                                    <div class="ml5 mr5 mt5">
                                        <div class="mb5">姓名：</div>
                                        <el-input clearable v-model="queryParams.trueName" placeholder="请输入姓名"
                                            class="input-with-select">
                                        </el-input>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="el-checkbox-group is-filterable el-transfer-panel__list" role="group"
                            aria-label="checkbox-group">
                            <p @click="choseAll" class="el-transfer-panel__header" style="padding-left:0;"><el-button
                                    class="ml5">全选</el-button></p>
                            <div style="height: calc(100vh - 300px);overflow-y:auto">
                                <div @click="changeCkecked(item)" v-show="showFillTeach(item)"
                                    class="el-checkbox is-checked el-transfer-panel__item" v-for="item in fillTeacherList">
                                    <span v-if="item.checkStatus==0" :class="item.isCkecked ? 'is-checked' : ''" class="el-checkbox__input">
                                        <input class="el-checkbox__original" type="checkbox" value="0">
                                        <span class="el-checkbox__inner"></span>
                                    </span>
                                    <span v-if="item.checkStatus!=0" class="el-checkbox__input is-disabled">
                                        <input class="el-checkbox__original" type="checkbox" value="0">
                                        <span class="el-checkbox__inner"></span>
                                    </span>
                                    
                                    <span class="el-checkbox__label"><span>{{ item.trueName }}&nbsp;&nbsp;{{ item.periodName
                                    }}&nbsp;&nbsp;{{ item.gradeName }}&nbsp;&nbsp;{{ item.subjectName }}</span>
                                    <span>&nbsp;&nbsp;（{{ item.checkStatus==0?'未分配':'已分配' }}）</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="el-transfer__buttons">
                    <div style="padding:9px;">分配给→</div>
                </div>
                <div class="el-transfer-panel" style="width:533px;">
                    <div style="text-align: center;font-size:20px;line-height:50px">分配审核人</div>
                    <div class="el-transfer-panel__body" style="border-top: 1px solid #ebeef5;height: calc(100vh - 190px);">
                        <div>
                            <el-row>
                                <el-col :span="24" class="mb0">
                                    <div class="ml5 mr5 mt5">
                                        <el-form-item label="姓名：">
                                            <el-input clearable v-model="queryParams.checkTrueName" placeholder="请输入姓名"
                                                class="input-with-select">
                                            </el-input>
                                        </el-form-item>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>
                        <div class="el-checkbox-group is-filterable el-transfer-panel__list" role="group"
                            aria-label="checkbox-group">
                            <div style="height: calc(100vh - 258px);overflow-y:auto">
                                <div @click="changeCkeckUser(item)" v-show="showcheck(item)"
                                    class="el-checkbox is-checked el-transfer-panel__item" v-for="item in checkUserList">
                                    <span :class="item.isCkecked ? 'is-checked' : ''" class="el-checkbox__input">
                                        <input class="el-checkbox__original" type="checkbox" value="0">
                                        <span class="el-checkbox__inner"></span>
                                    </span>
                                    <span class="el-checkbox__label"><span>{{ item.trueName }}&nbsp;&nbsp;{{ item.periodName
                                    }}&nbsp;&nbsp;{{ item.gradeName }}&nbsp;&nbsp;{{ item.subjectName }}</span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="" style="height: 50px;overflow-y: auto;padding: 5px;">
                <el-tag type="info" @close="tagClose(item)" style="margin-right: 10px;margin-bottom: 5px;"
                    v-if="fillTeacherList.length != 0" v-show="item.isCkecked" v-for="item in fillTeacherList" class="mx-1"
                    closable>
                    <span>{{ item.trueName }}</span>
                </el-tag>
            </div>
            <div>
                <div class="" style="position: absolute;left: 50%;transform: translateX(-50%);">
                    <el-button type="primary" @click="submitForm">分 &nbsp; 配</el-button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { archiveManagerList, archiveManagerCheckUserList, archiveManagerCheckList, archiveManagerDivide } from '@/api/admin/archive';
import { queryDict, orgListUserSchool } from '@/api/admin/public';

export default {
    name: "fileAllocation",
    components: {
    },
    data() {
        return {
            orgId: '',
            schoolList: [],
            fillTeacherList: [],
            checkUserList: [],
            periodList: [],
            gradeList: [],
            subjectList: [],
            queryParams: {
                trueName: '',
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                checkTrueName: '',
            },
        };
    },
    created() {
        this.getQueryDict();
        this.getSchoolList();
    },
    activated() {
    },
    watch: {
        $route(to, from) {
            console.log('to', to)
        },
    },
    methods: {
        submitForm() {
            var archiveUser = [];
            var checkUser = [];
            this.fillTeacherList.forEach(e => {
                if (e.isCkecked) {
                    archiveUser.push(e.userId)
                }
            })
            this.checkUserList.forEach(e => {
                if (e.isCkecked) {
                    checkUser.push(e.userId)
                }
            })
            if (archiveUser.length == 0) {
                this.$message.error('请选择提交审核的老师')
                return
            }
            if (checkUser.length == 0) {
                this.$message.error('请选择提交审核的老师')
                return
            }
            archiveManagerDivide({ archiveUser: archiveUser.join(','), checkUser: checkUser.join(',') }).then(res => {
                if (res.data.code == 1) {
                    this.$message.success('分配成功')
                    // this.orgId = ''
                    this.queryParams = {
                        trueName: '',
                        periodKey: '',
                        gradeKey: '',
                        subjectKey: '',
                        checkTrueName: '',
                    }
                    this.fillTeacherList = [];
                    this.checkUserList = [];
                    this.schoolId = ''
                    this.changeSchool()
                } else {
                    this.$message.error('操作失败')
                }
            })
        },
        showFillTeach(item) {
            console.log(item.periodKey, this.queryParams.periodKey);
            var isTg = true;
            if (this.queryParams.periodKey) {
                if (item.periodKey != this.queryParams.periodKey) {
                    isTg = false;
                }
            }
            if (this.queryParams.gradeKey) {
                if (item.gradeKey != this.queryParams.gradeKey) {
                    isTg = false;
                }
            }
            if (this.queryParams.subjectKey) {
                if (item.subjectKey && item.subjectKey.toString().indexOf(this.queryParams.subjectKey) == -1) {
                    isTg = false;
                }
            }
            if (this.queryParams.trueName) {
                if (item.trueName && item.trueName.toString().indexOf(this.queryParams.trueName) == -1) {
                    isTg = false;
                }
            }
            console.log(isTg);
            return isTg
        },
        showcheck(item) {
            var isTg = true;
            if (this.queryParams.checkTrueName) {
                if (item.trueName && item.trueName.toString().indexOf(this.queryParams.checkTrueName) == -1) {
                    isTg = false;
                }
            }
            console.log(isTg);
            return isTg
        },
        getQueryDict() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
        },
        changePeriod() {
            this.queryParams.gradeKey = '';
            this.queryParams.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.queryParams.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
            // this.$forceUpdate()
        },
        changeGrade() {
            this.queryParams.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.queryParams.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.queryParams.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
        },
        getSchoolList() {
            orgListUserSchool().then(res => {
                this.schoolList = res.data.data;
            })
        },
        changeCkecked(item) {
            if(item.checkStatus != 0){
                return
            }
            console.log(item);
            item.isCkecked = !item.isCkecked
            this.$forceUpdate()
        },
        changeCkeckUser(item) {
            this.checkUserList.forEach(e => {
                if (item.userId == e.userId) {
                    item.isCkecked = !item.isCkecked
                } else {
                    e.isCkecked = false;
                }
            })
        },
        tagClose(item) {
            item.isCkecked = false
        },
        choseAll() {
            this.fillTeacherList.forEach(e => {
                e.isCkecked = true;
            })
        },
        changeSchool() {
            this.queryParams = {
                trueName: '',
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                checkTrueName: '',
            }
            this.fillTeacherList = [];
            archiveManagerList({ orgId: this.orgId }).then(res => {
                this.fillTeacherList = res.data.data.filter(itemA => {
                    return itemA.fillStatus == 1
                })
                this.fillTeacherList.forEach(e => {
                    e.isCkecked = false;
                })
            })
            archiveManagerCheckUserList({ orgId: this.orgId }).then(res => {
                this.checkUserList = res.data.data;
            })
        }
    }
};
</script>
<style scoped></style>