<template>
    <div id="home-box">
        <!--顶部菜单-->
        <el-header style="background: rgb(56, 89, 223);">
          <el-row type="flex" align="middle">
            <!--左侧组织切换-->
            <el-col :span="21">
              <div class="disflex-al-ct">
                <div class="zbh-logo" :style="isActive ? 'width:0px;' : 'width:200px;'">
                  <img v-if="userInfo.orgNatureName && userInfo.orgNatureName == '励耘教研' && !isActive" style="width: 50px;"
                    src="../assets/lyjy.png" alt="">
                  <div :title="userInfo.orgNatureName" class="text-one"
                    style="color: #fff;width: 160px;overflow: hidden;text-align: left;margin-left: 10px;" v-if="!isActive">
                    {{ userInfo.orgNatureName }}</div>
                </div>
                <div style="flex: 1;" class="top-tp">
                </div>
              </div>
  
            </el-col>
            <!--个人中心及消息通知-->
            <el-col :span="3">
              <el-row justify="end" type="flex" align="middle">
                <el-col :span="24" :xl="24">
                  <div class="tr-img">
                    <el-dropdown class="cursor">
                      <el-avatar style="margin-left: 15px;" :size="30" :src="userHeadImg + '?_=' + Date.now()" />
                      <template #dropdown>
                        <el-dropdown-menu>
                          <div class="cursor" style="text-align: center;">{{
                            popname }}</div>
                          <el-dropdown-item style="text-align: center;" @click="logout">安全退出</el-dropdown-item>
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </div>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </el-header>
    </div>
  </template>
  <script>
  
  export default {
    components: {
    },
    name: "TbHeader",
    data() {
      return {
        userHeadImg: "",
        userInfo: {},
        logoTextWidth: 200,   // 左上角logo文字宽度
        isActive: false,      // 是否菜单折叠
        popname: '',
        // schoolName: '',
      }
    },
    computed: {
    },
    watch: {
      $route(to, from) {
        console.log('to', to)
      },
    },
    created() {
      this.$store.commit("zdy/setRole", this.$storage.get("role"));
      this.logoTextWidth = window.innerWidth * (20 / 100);
      this.watchWidth();
      this.popname = JSON.parse(this.$storage.get("userInfo")).trueName
      this.userHeadImg = JSON.parse(this.$storage.get("userInfo")).avatar
      this.userInfo = JSON.parse(this.$storage.get("userInfo"))
    },
    mounted() {
      // 监听网页大小
      window.onresize = () => {
        return (() => {
          this.watchWidth();
          console.log(window.innerWidth)
          this.logoTextWidth = window.innerWidth * (20 / 100);
        })();
      };
    },
    methods: {
      // 监听网页大小
      watchWidth() {
        if (window.innerWidth < 1000) {
          this.isActive = true;
          this.$store.commit("zdy/setIsActive", true);
        } else {
          this.isActive = false;
          this.$store.commit("zdy/setIsActive", false);
        }
      },
      logout() {
        this.$confirm("您正在退出系统，是否继续？", "退出系统", {
          confirmButtonText: "退出",
          cancelButtonText: "取消",
          type: "warning"
        }).then(() => {
          this.$storage.remove("token")
          this.$storage.remove("userInfo")
          this.$router.push({ path: "/login" });
        })
      },
    }
  }
  </script>
  <!--内联样式-->
  <style lang="scss" scoped>
  .tr-img {
    display: flex;
    align-items: center;
    justify-content: end;
  
    img {
      width: 20px;
      height: 24px;
      margin-left: 15px;
    }
  }
  
  .el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
  .el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
    color: #c2c0c0;
  }
  
  /deep/ .el-menu-item:hover {
    color: #333;
  }
  
  /deep/ .el-menu--collapse .el-sub-menu.is-active .el-sub-menu__title {
    color: var(--el-menu-active-color) !important;
  }
  
  /deep/ .top-tp .el-menu-item.is-active {
    border-bottom: 2px solid #08c7ff !important;
  }
  
  /deep/ .lftop .el-menu-item.is-active::after {
    content: '';
    position: absolute;
    left: 20px;
    width: 3px;
    height: 30%;
    background-color: #08c7ff;
  }
  
  /deep/ .lftop .el-menu-item.is-active {
    background: #596cfd;
    position: relative;
  }
  
  /deep/ .lftop .el-menu-item.is-active i {
    color: #08c7ff !important;
  }
  
  /deep/ .el-menu-item.is-active::after {
    background: #409eff;
    position: absolute;
    right: 0;
    height: 100%;
    width: 2px;
    // content: "";
  }
  
  .hamburgerBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
  }
  
  .hamburger {
    display: inline-block;
    vertical-align: middle;
    width: 20px;
    height: 20px;
    cursor: pointer;
    color: #e7efff;
  }
  
  .hamburger.is-active {
    transform: rotate(180deg);
  }
  
  .zbh-logo {
    height: 60px;
    line-height: 60px;
    display: flex;
    align-items: center;
    padding-left: 15px;
  
    // font-weight: bold;
    span {
      white-space: nowrap;
      font-size: 24px;
  
    }
  
    div {
      // white-space: nowrap;
      font-size: 24px;
  
    }
  
    img {
      width: 25px;
      padding: 0 10px;
    }
  }
  
  #home-box {
    width: 100%;
    height: 100%;
  
    .el-menu--horizontal {
      // border-bottom: 5px solid #409eff;
      border-color: transparent;
      height: 60px;
    }
  
    .el-main {
      // background: #f7f8f8;
      padding: 0 !important;
      background: #f1f1f1;
    }
  
    .el-container {
      height: 100%;
    }
  
    .el-aside {
      width: 20%;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      height: 100%;
      background: #f1f1f1;
  
      .zbh-logo {
        height: 8%;
        color: #F9F8F8;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: justify;
        background-color: #20222a;
      }
  
      .el-menu {
        border-top-right-radius: 6px;
        // overflow: hidden;
        border: none;
        // height: calc(100vh - 66px);
        height: calc(100vh - 75px);
        margin-top: 15px;
        // margin-top: 5px;
        background-color: #dfe4f7;
        color: #fff;
      }
  
    }
  
    .el-header {
      padding: 0 0;
      text-align: center;
      height: 60px !important;
      // width: 99% !important;
      // border-bottom: 5px solid #409eff;
  
      .el-row {
        height: 60px !important;
        padding-right: 7px;
      }
  
      .el-dropdown {
        // margin-left: 10px !important;
      }
    }
  }
  
  .schoolDrawer /deep/ .el-drawer.ttb {
    width: 20%;
    margin-left: 70% !important;
  }
  
  .scrollbar-flex-content {
    display: flex;
    height: auto !important;
  }
  
  .scrollbar-demo-item {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 50px;
    margin: 10px;
    text-align: center;
    border-radius: 4px;
    background: var(--el-color-danger-light-9);
    color: var(--el-color-danger);
  }
  </style>
  