<template>
    <div class="app-container">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel" v-if="isPtStatistics == 1"><a>一体化教研统计</a></el-breadcrumb-item>
                <el-breadcrumb-item @click="cancel" v-else><a>听评教研</a></el-breadcrumb-item>
                <el-breadcrumb-item>听评课详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 160px);overflow: auto;">
            <el-descriptions :column="3" class="detailFF" title="基本信息">
                <el-descriptions-item width="33.33%" label="市区县"><el-tree-select disabled :props="defaultProps"
                        v-model="form.countyId" :default-expand-all="true" :data="orgList" check-strictly
                        :render-after-expand="true" /></el-descriptions-item>
                <el-descriptions-item width="33.33%" label="学校">{{ form.schoolName }}</el-descriptions-item>
                <el-descriptions-item width="33.33%" label="学段">{{ form.periodName }}</el-descriptions-item>
                <el-descriptions-item width="33.33%" label="年级">{{ form.gradeName }}</el-descriptions-item>
                <el-descriptions-item width="33.33%" label="学科">{{ form.subjectName }}</el-descriptions-item>
                <el-descriptions-item width="33.33%" label="课程标题">{{ form.title }}</el-descriptions-item>
                <el-descriptions-item width="33.33%" label="备课性质">
                    <span v-if="form.lessonNature == 1">普通</span>
                    <span v-if="form.lessonNature == 2">名师工作室</span>
                    <span v-if="form.lessonNature == 3">名校长工作室</span>
                    <span v-if="form.lessonNature == 4">群组任务</span>
                </el-descriptions-item>
                <el-descriptions-item width="33.33%" label="工作室" v-if="form.groupName">{{ form.groupName
                }}</el-descriptions-item>
                <el-descriptions-item width="33.33%" label="类型">
                    <span v-if="form.type == 0">推门</span>
                    <span v-if="form.type == 1">线上</span>
                </el-descriptions-item>
                <el-descriptions-item width="33.33%" v-if="form.type == 0" label="听课地址">{{ form.address
                }}</el-descriptions-item>
                <el-descriptions-item width="33.33%" v-if="form.type == 1" label="听课地址">
                    <el-button @click="goTk" type="primary" size="mini" icon="">去听课
                    </el-button>
                </el-descriptions-item>
                <el-descriptions-item width="33.33%" label="任务时段">{{ timestampToTime(form.beginTime) }}至{{
                    timestampToTime(form.endTime)
                }}</el-descriptions-item>
                <el-descriptions-item width="33.33%" label="评课截止时间">{{ timestampToTime(form.rateEndTime)
                }}</el-descriptions-item>
                <el-descriptions-item width="33.33%" label="听评量表" v-if="form.scaleObj">
                    <span style="color: #409efe;" class="cursor" @click="preview(form.scaleObj)">{{ form.scaleObj?.title
                    }}</span>
                </el-descriptions-item>
                <el-descriptions-item width="33.33%" label="授课教师">{{ form.lectureTeacherName }}</el-descriptions-item>
                <!-- <el-descriptions-item width="33.33%" label="评课教师">{{ form.rateTeacherTxt }}</el-descriptions-item> -->
                <!-- <el-descriptions-item width="33.33%" label="听课教师">{{ form.listenTeacherTxt }}</el-descriptions-item> -->
                <!-- <el-descriptions-item width="33.33%" label="评课组长" v-if="form.leadTeacherName">{{ form.leadTeacherName }}</el-descriptions-item> -->
            </el-descriptions>
            <el-form-item v-if="form.listenTeacherTxt" class="fs14" label-width="68px" label="听课教师" prop="">
                <expendText :text="form.listenTeacherTxt"></expendText>
            </el-form-item>
            <el-form-item v-if="form.rateTeacherTxt" class="fs14" label-width="68px" label="评课教师" prop="">
                <expendText :text="form.rateTeacherTxt"></expendText>
            </el-form-item>
            <el-form-item class="fs14" label-width="68px" label="评课组长" prop="" v-if="form.leadTeacherName">
                <div>{{ form.leadTeacherName }}</div>
            </el-form-item>
            <!-- <el-row v-if="isShow && form.status != 3">
                <el-col :span="12">
                    <el-form-item class="fs14" label-width="82px" label="听课二维码" prop="">
                        <div style="position: relative;" class="csbbb">
                            <el-icon :size="20" @click.stop="handleDownload()" title="下载" class="eicon"
                                style="right: 15px;top: 5px;cursor: pointer;z-index: 99;">
                                <Download />
                            </el-icon>
                            <vueQr ref="dyplg" :text="qrCode.shareTkUrl" :size="qrCode.size" />
                        </div>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item class="fs14" label-width="82px" label="评课二维码" prop="">
                        <div style="position: relative;" class="csbbb1">
                            <el-icon :size="20" @click.stop="handleDownload1()" title="下载" class="eicon"
                                style="right: 15px;top: 5px;cursor: pointer;z-index: 99;">
                                <Download />
                            </el-icon>
                            <vueQr ref="dyplg1" :text="qrCode.sharePkUrl" :size="qrCode.size" />
                        </div>
                    </el-form-item>
                </el-col>
            </el-row> -->
            <el-form ref="form" :model="form" :rules="rules" label-width="120px">

                <el-form-item label-width="0" label="" v-show="form.type == 0">
                    <!-- <span>搜索框和地图试图都展示</span> -->
                    <aMap isDetail v-if="isShow" ref="zdyMap" @getGeocoder="getGeocoder" :oldCenter="oldCenter"
                        :isShowAMap="true">
                    </aMap>

                    <!-- <span>只展示搜索框 不展示地图试图</span>
                    <aMap :toParentsMap="toParentsMap" :isShowAMap="true" :isDisplayAMap="false"></aMap> -->
                </el-form-item>
                <el-col :span="24" v-if="form.recordList && form.recordList.length != 0">
                    <el-form-item label-width="75px" label="听评意见" prop="recordList">
                        <div>
                            <el-empty :image-size="80" v-if="form.recordList && form.recordList.length == 0"
                                description="暂无意见" />
                            <div v-for="(item, index) in form.recordList" class="mt15"
                                style="border-bottom: 1px solid #efefef;padding-bottom: 10px;width: 100%;">
                                <div style="display: flex;align-items: center;justify-content: space-between;">
                                    <div style="display: flex;align-items: center;color: #959494;">
                                        <el-avatar :size="30" :src="item.avatar" />
                                        <div class="ml10">{{ item.trueName }}</div>
                                    </div>
                                </div>
                                <div style="padding-left: 40px;">
                                    {{ item.content }}
                                </div>
                                <div style="padding-left: 40px;">
                                    <el-image style="width: 100px; height: 100px;margin:5px 5px 0 0;"
                                        v-for="(items, indexs) in item.imgList" :src="items" :zoom-rate="1.2" :max-scale="7"
                                        :min-scale="0.2" :preview-src-list="item.imgList" :initial-index="indexs"
                                        fit="cover" />
                                </div>

                                <div style="padding-left: 40px;color: #959494;font-size: 12px;" class="mt5">
                                    {{ timestampToTime(item.createTime) }}
                                </div>
                            </div>
                        </div>
                    </el-form-item>
                </el-col>
            </el-form>
        </el-card>
        <el-dialog :title="editNum != null ? '编辑评价维度' : '新增评价维度'" v-model="addQuse" width="60%">
            <el-form ref="quseform" :model="addQuseForm" :rules="addQuesrules" label-width="100px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item :label="addQuseForm.type == 3 ? '考核项目' : '题目名称'" prop="questionName">
                            <el-input type="textarea" v-model="addQuseForm.questionName" placeholder="最多1000个字"
                                maxlength="1000" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="题型" prop="type">
                            <el-select style="width: 100%;" v-model="addQuseForm.type" placeholder="请选择课题级别">
                                <el-option v-for="item in quesTypeList" :key="item.value" :label="item.label"
                                    :value="item.value" />
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="addQuseForm.type == 3 || addQuseForm.type == 4">
                    <el-col :span="12">
                        <el-form-item label="选项分数" prop="totalScore">
                            <el-input-number step-strictly v-model="addQuseForm.totalScore" :min="0" :max="100" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="addQuseForm.type == 1 || addQuseForm.type == 2">
                    <el-col>
                        <el-form-item label="选项" prop="quesXXList">
                            <el-table ref="quesXXList" border :data="addQuseForm.itemList" style="width: 100%"
                                max-height="250">
                                <el-table-column prop="itemName" label="选项文字">
                                    <template #default="scope">
                                        <el-input v-model="scope.row.itemName" placeholder="最多32个字" maxlength="32" />
                                    </template>
                                </el-table-column>
                                <el-table-column prop="itemValue" label="选项分数">
                                    <template #default="scope">
                                        <el-input-number step-strictly v-model="scope.row.itemValue" :min="0" :max="100" />
                                    </template>
                                </el-table-column>
                                <el-table-column fixed="right" label="操作" width="200">
                                    <template #default="scope">
                                        <el-button link type="primary" size="small"
                                            @click.prevent="deleteRow(scope.$index)">
                                            删除
                                        </el-button>
                                        <el-button link type="primary" size="small"
                                            @click.prevent="addQuesRow(scope.$index)">
                                            增加
                                        </el-button>
                                        <el-button link type="primary" size="small"
                                            @click.prevent="moveUpRow(scope.$index)">
                                            上移
                                        </el-button>
                                        <el-button link type="primary" size="small" @click.prevent="downRow(scope.$index)">
                                            下移
                                        </el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-button plain type="primary" class="mt10" style="width: 100%"
                                @click="onAddItem">增加</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <template #footer>
                <div class="disflex-row-center">
                    <el-button type="primary" @click="addQuesSumbit">确 定</el-button>
                    <el-button @click="addQuse = false">取 消</el-button>
                </div>
            </template>
        </el-dialog>
        <el-dialog :title="previewData.title ? previewData.title : '预览'" v-model="previewShow" width="60%">
            <div class="mb30" v-for="(item, index) in previewData.questionList">
                <div class="mb15" v-if="item.type != 3">
                    <h3 style="margin: 0 0;"><span style="padding-right: 10px;">{{ index + 1
                    }}.</span>{{ item.questionName }}
                        <span style="padding-left: 10px;color: #999;font-weight: normal;font-size: 14px;">[{{
                            item.type == 1 ? '单选' : item.type == 2 ? '多选' : item.type == 3 ? '评分' : item.type == 4 ? '文字内容'
                                : ''
                        }}]</span><span v-if="item.type == 4 && item.totalScore"
                            style="color: #efa030;font-weight: normal;font-size: 14px;">(分值：{{
                                item.totalScore }})</span>
                        <span v-if="item.type == 3" style="color: #efa030;font-weight: normal;font-size: 14px;">(满分：{{
                            item.totalScore }})</span>
                    </h3>
                </div>
                <div class="mb15" v-if="item.type == 3">
                    <div class="mb15">
                        <h3 style="margin: 0 0;"><span style="padding-right: 10px;">{{ index + 1
                        }}.</span>{{ item.questionName }}
                            <span style="padding-left: 10px;color: #999;font-weight: normal;font-size: 14px;">[{{
                                item.type == 1 ? '单选' : item.type == 2 ? '多选' : item.type == 3 ? '评分' : item.type == 4 ?
                                    '文字内容'
                                    : ''
                            }}]</span>
                            <span style="color: #efa030;font-weight: normal;font-size: 14px;margin-right: 15px;">(满分：{{
                                item.totalScore }})</span>
                        </h3>
                    </div>
                    <div>

                        <el-input-number size="small" step-strictly disabled :min="0" :max="100" />
                    </div>
                </div>
                <div v-if="item.type == 1">
                    <el-radio-group disabled class="ml-4">
                        <el-radio label="1" size="small" v-for="(items, indexs) in item.itemList">
                            <span style="color: #333;">{{ items.itemName }}</span><span style="color: #efa030;">(分值：{{
                                items.itemValue }})</span></el-radio>
                    </el-radio-group>
                </div>
                <div v-if="item.type == 2">
                    <el-checkbox-group disabled class="ml-4">
                        <el-checkbox size="small" v-for="(items, indexs) in item.itemList">
                            <span style="color: #333;">{{ items.itemName }}</span><span style="color: #efa030;">(分值：{{
                                items.itemValue }})</span>
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
                <div v-if="item.type == 4" style="width: 100%;">
                    <el-input style="width: 100%;" disabled :rows="2" type="textarea" placeholder="" />
                </div>
            </div>
        </el-dialog>
        <el-dialog @close="gbIfram" :title="'去听课'" v-model="isTk" width="80%" top="50px">
            <iframe id="cktpkfx" style="width: 100%;height:calc(100vh - 250px)" :src="form.address"
                frameborder="0"></iframe>
        </el-dialog>
    </div>
</template>

<script>
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import { timestampToTime } from "@/utils/date";
import aMap from '@/components/aMap/index' //引入封装好的组件
import { lessonCreate, lessonUpdate, delLessonFile } from "@/api/admin/lesson"
import { queryDict, userList, listHeadmasterGroup, listTeacherGroup } from "@/api/admin/public";
import { lessonInfo } from "@/api/admin/lesson";
import { orgListTypeOrg, orgListSchoolOrg, orgListTree, userListOrgUsers } from "@/api/admin/system";
import { rateScaleList, evaluationCreate, evaluationInfo, evaluationUpdate, rateScaleInfo } from "@/api/admin/evaluation";
import html2canvas from "html2canvas";

export default {
    name: "demoAdd",
    components: {
        aMap,
        vueQr
    },
    data() {
        return {
            orgList: [],
            timestampToTime,
            toParentsMap: {},
            qrCode: {
                shareTkUrl: '',
                sharePkUrl: '',
                size: 200,
            },
            isShow: false,
            oldCenter: [],
            rateScaleList: [],
            lessonId: '',
            periodList: [],
            gradeList: [],
            subjectList: [],
            lessonLevelList: [],
            userList: [],
            token: this.$storage.get("token"),
            // 遮罩层
            loading: true,
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                countyId: '',
                schoolId: '',
                type: 0,
                periodKey: '',
                gradeKey: '',
                subjectKey: '',
                lessonLevelKey: '',
                title: '',
                lectureTeacher: '',
                rateTeachers: '',
                rateTeacherList: [],
                scaleIds: '',
                address: '',
            },
            // 表单校验
            rules: {
            },
            delFileList: [],
            countyList: [],
            schoolList: [],
            previewData: {},
            previewShow: false,
            groupList: [],
            isPtStatistics: 0,
            isTk: false,
        };
    },
    created() {
        this.getOrgListTypeOrg();
        this.getQueryDict();
        this.getRateScaleList();
    },
    mounted() {
        if (this.$route.query.lessonId) {
            this.lessonId = this.$route.query.lessonId
            this.qrCode.shareTkUrl = 'http://47.93.86.37:8877/inviteMember?id=' + this.lessonId + '&type=1'
            this.qrCode.sharePkUrl = 'http://47.93.86.37:8877/inviteMember?id=' + this.lessonId + '&type=2'
            console.log(this.form)
            this.getlessonInfo()
        } else {
            this.isShow = true
        }
        if (this.$route.query.isPtStatistics) {
            this.isPtStatistics = this.$route.query.isPtStatistics
        }
    },
    unmounted() {
    },
    computed: {
    },
    methods: {
        goTk() {
            // window.open(this.form.address)
            // window.open(this.form.address,'location=no','',false);
            var newDate = new Date().getTime();
            console.log(newDate);
            console.log(this.form.beginTime)
            console.log(this.form.endTime)
            if (newDate > this.form.beginTime && newDate < this.form.endTime) {
                this.isTk = true;
                setTimeout(() => {
                    var iframe = document.getElementById("cktpkfx"); // 获取到要关闭的iframe元素
                    iframe.setAttribute("src", this.form.address);
                })
            } else {
                this.$message.error('已过/未到任务时段中')
            }
            // if (this.form.status == 3) {

            // }

        },
        gbIfram() {
            var iframe = document.getElementById("cktpkfx"); // 获取到要关闭的iframe元素
            iframe.setAttribute("src", '');
            console.log(iframe);
            if (typeof iframe !== "undefined" && iframe != null) {
                var contentWindow = iframe.contentWindow; // 获取到iframe内部的window对象
                if (typeof contentWindow !== "undefined" && contentWindow != null) {
                    console.log(1122);
                    contentWindow.close(); // 调用close()方法关闭iframe
                } else {
                    console.log("无效的iframe或者未加载完成！");
                }
            } else {
                console.log("没有找到指定的iframe元素！");
            }
        },
        changeLessonNature(e) {
            console.log(e);
            this.groupList = [];
            var userId = JSON.parse(this.$storage.get("userInfo")).userId
            if (e == 2) {
                listTeacherGroup().then(res => {
                    res.data.data.forEach(j => {
                        if (this.form.groupId == j.groupId) {
                            this.form.groupName = j.groupName
                        }
                        j.memberList.forEach(m => {
                            if (m.memberRole == 0 || m.memberRole == 1) {
                                if (m.memberUserId == userId) {
                                    this.groupList.push(j)
                                    // throw new Error();
                                }
                            }
                        })
                    })
                })
            } else if (e == 3) {
                listHeadmasterGroup().then(res => {
                    res.data.data.forEach(j => {
                        if (this.form.groupId == j.groupId) {
                            this.form.groupName = j.groupName
                        }
                        j.memberList.forEach(m => {
                            if (m.memberRole == 0 || m.memberRole == 1) {
                                if (m.memberUserId == userId) {
                                    this.groupList.push(j)
                                    // throw new Error();
                                }
                            }
                        })
                    })
                })
            }
        },
        preview(item) {
            console.log(item)
            rateScaleInfo({ scaleId: item.scaleId }).then(res => {
                this.previewData = res.data.data;
                this.previewShow = true;
            })
        },
        getGeocoder(e) {
            console.log(e);
            if (this.form.type == 0) {
                this.form.lat = e.center[1];
                this.form.lng = e.center[0];

                this.form.address = e.formattedAddress;
            }

        },
        getRateScaleList() {
            rateScaleList().then(res => {
                this.rateScaleList = res.data.data;
            })
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.response.data) {
                this.delFileList.push(file.response.data)
            }
        },
        getlessonInfo() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
                evaluationInfo({ lessonId: this.lessonId }).then(res => {
                    this.changeLessonNature(res.data.data.lessonNature)
                    this.form = res.data.data;
                    // var scaleList = []
                    // this.form.rateTeacherList = this.form.rateTeachers.split(',')
                    // this.form.scaleList.forEach(e => {
                    //     scaleList.push(e.scaleId)
                    // })
                    var rateTeacherTxt = [];
                    this.form.rateTeacherList.forEach(e => {
                        rateTeacherTxt.push(e.trueName)
                    })
                    this.form.rateTeacherTxt = rateTeacherTxt.join('、')
                    var listenTeacherTxt = []
                    this.form.listenTeacherList.forEach(e => {
                        listenTeacherTxt.push(e.trueName)
                    })
                    this.form.listenTeacherTxt = listenTeacherTxt.join('、')
                    this.countyList.forEach(e => {
                        if (this.form.countyId == e.orgId) {
                            this.form.countName = e.orgName;
                        }
                    })
                    if (this.form.scaleList.length != 0) {
                        this.form.scaleObj = this.form.scaleList[0]
                    }
                    console.log(this.form)
                    orgListSchoolOrg({ orgId: this.form.countyId }).then(res => {
                        this.schoolList = res.data.data;
                        this.schoolList.forEach(e => {
                            if (e.orgId == this.form.schoolId) {
                                this.form.schoolName = e.orgName;
                            }
                        })
                    })

                    if (this.form.type == 0) {
                        this.oldCenter = [this.form.lng, this.form.lat]

                    }
                    if (this.form.scaleList.length != 0) {
                        this.form.scaleIds = this.form.scaleList[0].scaleId;
                    }

                    console.log(this.periodList)
                    this.periodList.forEach(j => {
                        if (this.form.periodKey == j.dictKey) {
                            this.gradeList = j.childrenList;
                        }
                    })
                    this.periodList.forEach(e => {
                        if (this.form.periodKey == e.dictKey) {
                            e.childrenList.forEach(j => {
                                if (this.form.gradeKey == j.dictKey) {
                                    this.subjectList = j.childrenList;
                                }
                            })
                        }
                    })
                    this.isShow = true;
                    if (this.form.type == 0) {
                        setTimeout(() => {
                            this.$refs.zdyMap.center = this.oldCenter;
                            this.$refs.zdyMap.componentMarker.position = this.oldCenter;
                        }, 100)
                    }

                })
            })

        },
        handleXTChange(file) {
            console.log(file)
        },
        handleJAChange(file) {
            console.log(file)

        },
        handleKjChange(file) {
            console.log(file)

        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        startDateConfirm(e) {
            if (e > this.form.endTime) {
                this.form.endTime = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginTime) {
                this.form.beginTime = '';
            }
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginTime === '' || !this.form.beginTime) {
                callback(new Error('请选择开始日期'))
            } else if (this.form.endTime === '' || !this.form.endTime) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date()) - 86400000
        },
        enddisabledDate(current) {
            if (this.form.beginTime) {
                return current && current < this.form.beginTime
            } else {
                return current && current < Number(new Date()) - 86400000
            }
        },
        changeCounty() {
            this.form.schoolId = '';
            this.schoolList = [];
            orgListSchoolOrg({ orgId: this.form.countyId }).then(res => {
                this.schoolList = res.data.data;
            })
        },
        changePeriod() {
            this.form.gradeKey = '';
            this.form.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.form.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
        },
        changeGrade() {
            this.form.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.form.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.form.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
        },
        getOrgListTypeOrg() {
            orgListTypeOrg({ orgType: 2 }).then(res => {
                this.countyList = res.data.data;
            })
            // if (this.$storage.get("role") == 1 || this.$storage.get("role") == 2) {
            this.defaultProps = {
                children: 'childrenList',
                label: 'orgName',
                value: 'orgId'
            }
            orgListTree().then(res => {
                res.data.data.forEach(e => {
                    if (e.childrenList) {
                        e.childrenList = e.childrenList.filter(itemA => {
                            return itemA.orgType != 5;
                        })
                        e.childrenList.forEach(m => {
                            if (m.childrenList) {
                                m.childrenList = m.childrenList.filter(itemB => {
                                    return itemB.orgType != 5;
                                })
                            }
                        })
                    }
                })
                this.orgList = res.data.data;
                this.$forceUpdate()
            })
            // } else {
            //     this.defaultProps = {
            //         children: '',
            //         label: 'orgName',
            //         value: 'orgId'
            //     }
            //     userListOrgUsers().then(res => {
            //         res.data.data.orgList.forEach(e => {
            //         })
            //         this.orgList = res.data.data.orgList;
            //         this.$forceUpdate()
            //     })
            // }
        },
        getQueryDict() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
            queryDict({ code: 'topic_level' }).then(res => {
                this.lessonLevelList = res.data.data;
            })
            userList().then(res => {
                this.userList = res.data.data;
            })
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.form.bannerUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        /** 提交按钮 */
        submitForm() {
            console.log(this.form)
            this.form.rateTeachers = this.form.rateTeacherList.toString()
            // this.form.scaleIds = this.form.scaleList.toString()
            this.$refs["form"].validate(valid => {
                if (valid) {
                    var obj = JSON.parse(JSON.stringify(this.form));
                    if (obj.type == 1) {
                        obj.lat = ''
                        obj.lng = ''
                    }
                    delete obj.rateTeacherList
                    // delete obj.scaleList

                    if (this.lessonId) {
                        evaluationUpdate(obj).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("新增成功");
                                this.replace('/evaluation', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });

                    } else {
                        evaluationCreate(obj).then(res => {
                            if (res.data.code == 1) {
                                this.$message.success("编辑成功");
                                this.replace('/evaluation', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            if (this.isPtStatistics == 1) {
                this.replace('/ptStatistics')
            } else {
                this.replace('/evaluation')
            }
            // this.jAFileList = [];

            // this.$emit('isTg', true)
        },
        handleDownload() {
            console.log(this.$refs.dyplg);
            html2canvas(this.$refs.dyplg.$el, {
                backgroundColor: "#ffffff",
                useCORS: true,
                allowTaint: true,
                scale: 2,
                dpi: 300
            }).then(async canvas => {
                // 生成图片地址
                var imgUrl = canvas.toDataURL('image/png');
                // 创建一个a标签，用来下载图片
                var eleLink = document.createElement('a');
                // // 转换后的图片地址
                eleLink.href = imgUrl;
                // // 文件名设置
                eleLink.download = this.form.title + '-听课二维码';
                // // 下载
                eleLink.click();
            })
        },
        handleDownload1() {
            console.log(this.$refs.dyplg1);
            html2canvas(this.$refs.dyplg1.$el, {
                backgroundColor: "#ffffff",
                useCORS: true,
                allowTaint: true,
                scale: 2,
                dpi: 300
            }).then(async canvas => {
                // 生成图片地址
                var imgUrl = canvas.toDataURL('image/png');
                // 创建一个a标签，用来下载图片
                var eleLink = document.createElement('a');
                // // 转换后的图片地址
                eleLink.href = imgUrl;
                // // 文件名设置
                eleLink.download = this.form.title + '-评课二维码';
                // // 下载
                eleLink.click();
            })
        },
    }
};
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 178px;
    height: 178px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}
.csbbb1{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}
.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}
.csbbb1:hover .eicon {
    display: block;
}

.detailFF /deep/ .el-select__suffix {
    display: none;
}

.detailFF /deep/ .el-select {
    width: 60% !important;
}

.detailFF /deep/ .el-select__wrapper.is-disabled {
    cursor: unset;
    background: transparent;
    border: 0;
    box-shadow: none;
    padding: 0;
}

.detailFF /deep/ .el-select__wrapper.is-disabled .el-select__selected-item {
    color: #333;
}

.detailFF /deep/ .el-select .el-input.is-disabled .el-input__inner {
    color: #333;
    -webkit-text-fill-color: #333;
}

.detailFF /deep/ .el-select__wrapper.is-disabled {
    cursor: unset;
}
</style>