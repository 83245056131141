<template>
    <div class="app-container">
        <div class="x-nav">
            <el-breadcrumb :separator="'＞'">
                <el-breadcrumb-item @click="cancel" v-if="isSzpxStatistics == 1"><a>师资培训统计</a></el-breadcrumb-item>
                <el-breadcrumb-item @click="cancel" v-else><a>师资培训</a></el-breadcrumb-item>
                <el-breadcrumb-item>培训任务详情</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <el-card shadow="never" style="height: calc(100vh - 160px);overflow: auto;">
            <el-descriptions :column="3" class="detailFF" title="基本信息">
                <el-descriptions-item width="33.33%" label="任务标题">{{ form.title }}</el-descriptions-item>
                <el-descriptions-item label="类型">
                    <span v-if="form.type == 0">线上直播</span>
                    <span v-if="form.type == 1">线上录播</span>
                    <span v-if="form.type == 2">线下培训</span>
                </el-descriptions-item>
                <el-descriptions-item label="培训性质" v-if="form.trainNature">
                    <span v-if="form.trainNature == 1">普通</span>
                    <span v-if="form.trainNature == 2">名师工作室</span>
                    <span v-if="form.trainNature == 3">名校长工作室</span>
                    <span v-if="form.trainNature == 4">群组任务</span>
                </el-descriptions-item>
                <el-descriptions-item label="群组" v-if="form.groupName">{{ form.groupName }}</el-descriptions-item>
                <el-descriptions-item v-if="form.type == 2 && form.signInBeginTime" label="签到时间">{{
                    timestampToDate(form.signInBeginTime) }}至{{ timestampToDate(form.signInEndTime)
    }}</el-descriptions-item>
                <el-descriptions-item v-if="form.type == 2 && form.signOutBeginTime" label="签退时间">{{
                    timestampToDate(form.signOutBeginTime) }}至{{ timestampToDate(form.signOutEndTime)
    }}</el-descriptions-item>
                <el-descriptions-item v-if="form.type == 2 && form.secondSignInBeginTime && form.signCount == 4"
                    label="二次签到时间">{{ timestampToDate(form.secondSignInBeginTime) }}至{{
                        timestampToDate(form.secondSignInEndTime) }}</el-descriptions-item>
                <el-descriptions-item v-if="form.type == 2 && form.secondSignOutBeginTime && form.signCount == 4"
                    label="二次签退时间">{{ timestampToDate(form.secondSignOutBeginTime) }}至{{
                        timestampToDate(form.secondSignOutEndTime) }}</el-descriptions-item>
                <el-descriptions-item v-if="form.type == 2 && form.signInAddress" label="签到地址">{{ form.signInAddress
                }}</el-descriptions-item>
                <el-descriptions-item v-if="form.type == 2 && form.signTotalCount" label="签到总次数">{{ form.signTotalCount
                }}</el-descriptions-item>
                <el-descriptions-item label="培训级别" v-if="form.trainLevelName">{{ form.trainLevelName
                }}</el-descriptions-item>
                <!-- <el-descriptions-item width="33.33%" label="参与人员" v-if="form.joinUsersText">{{ form.joinUsersText }}</el-descriptions-item> -->
                <el-descriptions-item label="任务时段" v-if="form.beginTime">{{ timestampToTime(form.beginTime) }}至{{
                    timestampToTime(form.endTime) }}</el-descriptions-item>
            </el-descriptions>
            <el-form-item class="fs14" label-width="68px" label="参与人员" prop="" v-if="form.joinUsersText">
                <expendText :text="form.joinUsersText"></expendText>
            </el-form-item>
            <!-- <el-form-item class="fs14" label-width="82px" label="邀请二维码" prop="" v-if="isShow && form.status != 2">
                <div style="position: relative;" class="csbbb">
                    <el-icon :size="20" @click.stop="handleDownload()" title="下载" class="eicon"
                        style="right: 15px;top: 5px;cursor: pointer;z-index: 99;">
                        <Download />
                    </el-icon>
                    <vueQr ref="dyplg" :text="qrCode.shareUrl" :size="qrCode.size" />
                </div>
            </el-form-item> -->
            <el-form ref="form" :model="form" :rules="rules" label-width="100px">
                <el-row>
                    <el-col :span="24">
                        <el-form-item class="avatar-uploader" label="任务封面" prop="coverUrl">
                            <img v-if="form.coverUrl" :src="form.coverUrl" class="avatar" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="" v-show="form.type == 2">
                    <!-- <span>搜索框和地图试图都展示</span> -->
                    <aMap isDetail v-if="isShow" ref="zdyMap" @getGeocoder="getGeocoder" :oldCenter="oldCenter"
                        :isShowAMap="true">
                    </aMap>

                    <!-- <span>只展示搜索框 不展示地图试图</span>
                    <aMap :toParentsMap="toParentsMap" :isShowAMap="true" :isDisplayAMap="false"></aMap> -->
                </el-form-item>
                <el-row v-if="form.type == 2">
                    <el-col :span="24">
                        <el-form-item label="签到范围(m)" prop="signInDistance">
                            {{ form.signInDistance }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.type == 1">
                    <el-col :span="24">
                        <el-form-item label-width="97px" label="线上培训课程" prop="linkUrl">
                            <div @click="handleLookVideo(item)" v-for="item in form.courseList" style="color: #409efe;"
                                class="cursor">
                                {{ item.courseName }}
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="form.type == 0">
                    <el-col :span="24">
                        <el-form-item label="线上培训地址" prop="linkUrl">
                            <el-button @click="goTk" type="primary" size="mini" icon="">去培训
                            </el-button>
                            <!-- <div @click="handleLink(form.linkUrl)" style="color: #409efe;" class="cursor">{{ form.linkUrl }}
                            </div> -->
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="24">
                        <el-form-item label="培训内容" prop="content">
                            {{ form.content }}
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-col :span="12" v-if="form.scaleList && form.scaleList.length != 0">
                    <el-form-item label="培训任务量表" prop="scaleList">
                        <div v-for="item in form.scaleList" style="color: #409efe;width: 100%;">
                            <div class="cursor" @click="preview(item)">{{ item.title }}</div>
                        </div>
                    </el-form-item>
                </el-col>
                <el-row v-if="form.fileList && form.fileList.length != 0">
                    <el-col :span="24">
                        <el-form-item label="附件" prop="">
                            <div @click="xz(item)" v-for="item in form.fileList" class="mb10 cursor">
                                <img v-if="item.status == 1" style="width: 350px;height:300px;object-fit: cover;"
                                    :src="item.imgUrl + '/1.png'" alt="">
                                <div>{{ item.fileName }}</div>
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </el-card>
        <el-dialog :title="previewData.title ? previewData.title : '预览'" v-model="previewShow" width="60%">
            <div class="mb30" v-for="(item, index) in previewData.questionList">
                <div class="mb15" v-if="item.type != 3">
                    <h3 style="margin: 0 0;"><span style="padding-right: 10px;">{{ index + 1
                    }}.</span>{{ item.questionName }}
                        <span style="padding-left: 10px;color: #999;font-weight: normal;font-size: 14px;">[{{
                            item.type == 1 ? '单选' : item.type == 2 ? '多选' : item.type == 3 ? '评分' : item.type == 4 ? '文字内容'
                                : ''
                        }}]</span><span v-if="item.type == 4 && item.totalScore"
                            style="color: #efa030;font-weight: normal;font-size: 14px;">(分值：{{
                                item.totalScore }})</span>
                        <span v-if="item.type == 3" style="color: #efa030;font-weight: normal;font-size: 14px;">(满分：{{
                            item.totalScore }})</span>
                    </h3>
                </div>
                <div class="mb15" v-if="item.type == 3">
                    <div class="mb15">
                        <h3 style="margin: 0 0;"><span style="padding-right: 10px;">{{ index + 1
                        }}.</span>{{ item.questionName }}
                            <span style="padding-left: 10px;color: #999;font-weight: normal;font-size: 14px;">[{{
                                item.type == 1 ? '单选' : item.type == 2 ? '多选' : item.type == 3 ? '评分' : item.type == 4 ?
                                    '文字内容'
                                    : ''
                            }}]</span>
                            <span style="color: #efa030;font-weight: normal;font-size: 14px;margin-right: 15px;">(满分：{{
                                item.totalScore }})</span>
                        </h3>
                    </div>
                    <div>

                        <el-input-number size="small" step-strictly disabled :min="0" :max="100" />
                    </div>
                </div>
                <div v-if="item.type == 1">
                    <el-radio-group disabled class="ml-4">
                        <el-radio label="1" size="small" v-for="(items, indexs) in item.itemList">
                            <span style="color: #333;">{{ items.itemName }}</span><span style="color: #efa030;">(分值：{{
                                items.itemValue }})</span></el-radio>
                    </el-radio-group>
                </div>
                <div v-if="item.type == 2">
                    <el-checkbox-group disabled class="ml-4">
                        <el-checkbox size="small" v-for="(items, indexs) in item.itemList">
                            <span style="color: #333;">{{ items.itemName }}</span><span style="color: #efa030;">(分值：{{
                                items.itemValue }})</span>
                        </el-checkbox>
                    </el-checkbox-group>
                </div>
                <div v-if="item.type == 4" style="width: 100%;">
                    <el-input style="width: 100%;" disabled :rows="2" type="textarea" placeholder="" />
                </div>
            </div>
        </el-dialog>
        <el-dialog @close="gbIfram" :title="'去培训'" v-model="isTk" width="80%" top="50px">
            <iframe id="ckpxspfx" style="width: 100%;height:calc(100vh - 250px)" :src="form.linkUrl"
                frameborder="0"></iframe>
        </el-dialog>
    </div>
</template>

<script>
import vueQr from 'vue-qr/src/packages/vue-qr.vue'
import { timestampToTime, timestampToDate } from "@/utils/date";
import aMap from '@/components/aMap/index' //引入封装好的组件
import { lessonCreate, lessonUpdate, delLessonFile } from "@/api/admin/lesson"
import { queryDict, userList } from "@/api/admin/public";
import { lessonInfo } from "@/api/admin/lesson";
import { orgListTypeOrg, orgListSchoolOrg } from "@/api/admin/system";
import { rateScaleInfo } from "@/api/admin/evaluation";
import { trainCreate, trainUpdate, trainInfo, trainDeleteFile } from "@/api/admin/train";
import html2canvas from "html2canvas";

export default {
    name: "demoAdd",
    components: {
        aMap,
        vueQr
    },
    data() {
        return {
            timestampToTime,
            timestampToDate,
            fileList: [],
            toParentsMap: {

            },
            isShow: false,
            oldCenter: [],
            trainId: '',
            periodList: [],
            gradeList: [],
            subjectList: [],
            lessonLevelList: [
            ],
            userList: [],
            token: this.$storage.get("token"),
            // 遮罩层
            loading: true,
            // 非单个禁用
            single: true,
            // 表单参数
            form: {
                coverUrl: '',
                type: 0,
                title: '',
                joinUsersList: [],
                signInBeginTime: '',
                signInEndTime: '',
                signOutBeginTime: '',
                signOutEndTime: '',
                signInAddress: '',
                signInDistance: 10,
                trainLevel: '',
                linkUrl: '',
                beginTime: '',
                endTime: '',
                content: ''
            },
            // 表单校验
            rules: {
            },
            delFileList: [],
            countyList: [],
            schoolList: [],
            previewData: {},
            previewShow: false,
            isSzpxStatistics: 0,
            isTk: false,
            qrCode: {
                shareUrl: '',
                size: 200,
            },
        };
    },
    created() {
        this.getOrgListTypeOrg();
        this.getQueryDict();
    },
    mounted() {
        if (this.$route.query.trainId) {
            this.trainId = this.$route.query.trainId
            this.qrCode.shareUrl = 'http://47.93.86.37:8877/inviteMember?id=' + this.trainId + '&type=3'
            console.log(this.form)
            this.getlessonInfo()
        } else {
            this.isShow = true
        }
        if (this.$route.query.isSzpxStatistics) {
            this.isSzpxStatistics = this.$route.query.isSzpxStatistics
        }
    },
    unmounted() {
        if (this.trainId) {
            try {
                console.log(332)
                this.fileList.forEach(e => {
                    console.log(e)
                    if (e.response.code) {
                        trainDeleteFile({ fileUrl: e.response.data });
                    }
                })
            } catch (e) {
                console.log('e', e)
            }
        } else {
            try {
                this.fileList.forEach(e => {
                    trainDeleteFile({ fileUrl: e.response.data });
                })
            } catch (e) {
                console.log('e', e)
            }

        }
    },
    computed: {
    },
    methods: {
        handleDownload() {
            console.log(this.$refs.dyplg);
            html2canvas(this.$refs.dyplg.$el, {
                backgroundColor: "#ffffff",
                useCORS: true,
                allowTaint: true,
                scale: 2,
                dpi: 300
            }).then(async canvas => {
                // 生成图片地址
                var imgUrl = canvas.toDataURL('image/png');
                // 创建一个a标签，用来下载图片
                var eleLink = document.createElement('a');
                // // 转换后的图片地址
                eleLink.href = imgUrl;
                // // 文件名设置
                eleLink.download = this.form.title + '-邀请二维码';
                // // 下载
                eleLink.click();
            })
        },
        goTk() {
            this.isTk = true;
            // window.open(this.form.linkUrl)
            // window.open(this.form.address,'location=no','',false);
            setTimeout(() => {
                var iframe = document.getElementById("ckpxspfx"); // 获取到要关闭的iframe元素
                iframe.setAttribute("src", this.form.linkUrl);
            })
        },
        gbIfram() {
            var iframe = document.getElementById("ckpxspfx"); // 获取到要关闭的iframe元素
            iframe.setAttribute("src", '');
            console.log(iframe);
            if (typeof iframe !== "undefined" && iframe != null) {
                var contentWindow = iframe.contentWindow; // 获取到iframe内部的window对象
                if (typeof contentWindow !== "undefined" && contentWindow != null) {
                    console.log(1122);
                    contentWindow.close(); // 调用close()方法关闭iframe
                } else {
                    console.log("无效的iframe或者未加载完成！");
                }
            } else {
                console.log("没有找到指定的iframe元素！");
            }
        },
        preview(item) {
            console.log(item)
            rateScaleInfo({ scaleId: item.scaleId }).then(res => {
                this.previewData = res.data.data;
                this.previewShow = true;
            })
        },
        handleLink(row) {
            window.open(row)
            // window.location.href=row;
        },
        handleLookVideo(row) {
            this.$router.push({
                path: "/trainCourseLookVideo",
                query: {
                    courseId: row.courseId,
                    trainId: this.trainId,
                    isLook: 1,
                    isTraVourse: 1
                }
            });
        },
        xz(item) {
            let routeUrl = this.$router.resolve({
                path: '/lessonFileDetail',// 这里的路径就可以正常的写，不需要添加_blank: true
                query: { data: JSON.stringify(item) }
            })
            window.open(routeUrl.href, '_blank')
            // download(item.fileUrl, item.fileName)
        },
        checkCoverValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.coverUrl != '') {
                callback()
            } else {
                callback(new Error('请上传封面'))
            }
        },
        getGeocoder(e) {
            console.log(e);
            this.form.lat = e.center[1];
            this.form.lng = e.center[0];
            this.form.signInAddress = e.formattedAddress;
        },
        handleRemove(file, uploadFiles) {
            console.log(file, uploadFiles)
            if (file.response.data) {
                this.delFileList.push(file.response.data)
            }
        },
        getlessonInfo() {
            queryDict({ code: 'topic_level' }).then(resb => {
                this.lessonLevelList = resb.data.data;
                trainInfo({ trainId: this.trainId }).then(res => {
                    this.form = res.data.data;
                    this.lessonLevelList.forEach(e => {
                        if (e.dictKey == this.form.trainLevel) {
                            this.form.trainLevelName = e.dictValue
                        }
                    })
                    var textList = [];
                    this.form.joinUserList.forEach(e => {
                        textList.push(e.trueName)
                    })
                    this.form.joinUsersText = textList.join('、')
                    if (this.form.type == 2) {
                        this.oldCenter = [this.form.lng, this.form.lat]
                    }
                    this.form.fileList.forEach(e => {
                        this.fileList.push({
                            name: e.fileName,
                            fileId: e.fileId,
                            url: e.fileUrl,
                            response: {
                                data: e.fileUrl
                            }
                        })
                    })
                    this.isShow = true;
                    if (this.form.type == 2) {
                        setTimeout(() => {
                            this.$refs.zdyMap.center = this.oldCenter;
                            this.$refs.zdyMap.componentMarker.position = this.oldCenter;
                        }, 100)
                    }

                })
            })
        },
        handleXTChange(file) {
            console.log(file)
        },
        handleJAChange(file) {
            console.log(file)

        },
        handleKjChange(file) {
            console.log(file)

        },
        beforeJAUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'application/pdf' || rawFile.type == 'application/vnd.ms-powerpoint' || rawFile.type == 'application/vnd.openxmlformats-officedocument.presentationml.presentation')) {
                this.$message.error('文件必须是.pdf,.ppt,.pptx格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 200) {
                this.$message.error('文件大小不能超过200M')
                return false
            }
            return true
        },
        startDateConfirm(e) {
            if (e > this.form.endTime) {
                this.form.endTime = '';
            }
        },
        startSignInDateConfirm(e) {
            if (e > this.form.signInEndTime) {
                this.form.signInEndTime = '';
            }
        },
        startSignOutDateConfirm(e) {
            if (e > this.form.signOutEndTime) {
                this.form.signOutEndTime = '';
            }
        },
        endDateConfirm(e) {
            console.log(e,)
            if (e < this.form.beginTime) {
                this.form.beginTime = '';
            }
        },
        endSignInDateConfirm(e) {
            console.log(e,)
            if (e < this.form.signInBeginTime) {
                this.form.signInBeginTime = '';
            }
        },
        endSignOutDateConfirm(e) {
            console.log(e,)
            if (e < this.form.signOutBeginTime) {
                this.form.signOutBeginTime = '';
            }
        },
        checkValidator(rule, value, callback) {
            // rule规则对象
            // value关联表单值
            // callback() 回传结果
            if (this.form.beginTime === '' || !this.form.beginTime) {
                callback(new Error('请选择开始日期'))
            } else if (this.form.endTime === '' || !this.form.endTime) {
                callback(new Error('请选择结束日期'))
            } else {
                callback()
            }
        },
        disabledDate(current) {
            return current && current < Number(new Date()) - 86400000
        },
        enddisabledDate(current) {
            if (this.form.beginTime) {
                return current && current < this.form.beginTime
            } else {
                return current && current < Number(new Date()) - 86400000
            }
        },
        changeCounty() {
            this.form.schoolId = '';
            this.schoolList = [];
            orgListSchoolOrg({ orgId: this.form.countyId }).then(res => {
                this.schoolList = res.data.data;
            })
        },
        changePeriod() {
            this.form.gradeKey = '';
            this.form.subjectKey = '';
            this.gradeList = [];
            this.subjectList = [];
            this.periodList.forEach(j => {
                if (this.form.periodKey == j.dictKey) {
                    this.gradeList = j.childrenList;
                }
            })
        },
        changeGrade() {
            this.form.subjectKey = '';
            this.subjectList = []
            this.periodList.forEach(e => {
                if (this.form.periodKey == e.dictKey) {
                    e.childrenList.forEach(j => {
                        if (this.form.gradeKey == j.dictKey) {
                            this.subjectList = j.childrenList;
                        }
                    })
                }
            })
        },
        getOrgListTypeOrg() {
            orgListTypeOrg({ orgType: 2 }).then(res => {
                this.countyList = res.data.data;
            })
        },
        getQueryDict() {
            queryDict({ code: 'lession_xd_nj_xk' }).then(res => {
                this.periodList = res.data.data;
            })
            // queryDict({ code: 'topic_level' }).then(res => {
            //     this.lessonLevelList = res.data.data;
            // })
            userList().then(res => {
                this.userList = res.data.data;
            })
        },
        handleAvatarSuccess(response, uploadFile) {
            console.log(response, uploadFile);
            if (uploadFile.response.code == 1) {
                this.form.coverUrl = uploadFile.response.data;
            } else {
                this.$message.error("上传失败")
            }

        },
        beforeAvatarUpload(rawFile) {
            console.log(rawFile)
            if (!(rawFile.type == 'image/jpeg' || rawFile.type == 'image/png' || rawFile.type == 'image/jpg')) {
                this.$message.error('图片必须是jpeg、png、jpg格式的')
                return false
            } else if (rawFile.size / 1024 / 1024 > 10) {
                this.$message.error('图片大小不能超过10M')
                return false
            }
            return true
        },
        /** 提交按钮 */
        submitForm() {
            console.log(this.form)
            var fileList = [];
            this.fileList.forEach((e) => {
                if (e.response.code == 1) {
                    fileList.push(e.response.data);
                }
            })
            this.form.joinUsers = this.form.joinUsersList.toString()
            this.$refs["form"].validate(valid => {
                if (valid) {
                    var obj = JSON.parse(JSON.stringify(this.form));
                    obj.fileUrls = fileList.toString();
                    delete obj.joinUsersList

                    if (this.trainId) {
                        trainUpdate(obj).then(res => {
                            if (res.data.code == 1) {
                                this.fileList = []
                                this.delFileList.forEach(e => {
                                    trainDeleteFile({ fileUrl: e });
                                })
                                this.$message.success("新增成功");
                                this.replace('/train', { isSx: 1 })
                                // this.$emit('isTg', true);
                            } else {
                                this.$message.error("操作失败")
                            }

                        });

                    } else {
                        trainCreate(obj).then(res => {
                            if (res.data.code == 1) {
                                this.fileList = []
                                this.delFileList.forEach(e => {
                                    trainDeleteFile({ fileUrl: e });
                                })
                                this.$message.success("编辑成功");
                                this.replace('/train', { isSx: 1 })
                            } else {
                                this.$message.error("操作失败")
                            }
                        })
                    }

                }
            });
        },

        // 取消按钮
        cancel() {
            if (this.isSzpxStatistics == 1) {
                this.replace('/szpxStatistics')
            } else {
                this.replace('/train')
            }
            // this.$emit('isTg', true)
        },
    }
};
</script>
<style scoped>
.avatar-uploader .avatar {
    width: 270px;
    height: 152px;
    display: block;
    object-fit: cover;
}

.avatar-uploader /deep/ .el-upload {
    border: 1px dashed var(--el-border-color);
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: var(--el-transition-duration-fast);
}

.avatar-uploader .el-upload:hover {
    border-color: var(--el-color-primary);
}

.el-icon.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 270px;
    height: 152px;
    text-align: center;
}

/deep/ .el-input-group__append {
    background: var(--el-color-primary);
    color: #fff;
}

/deep/ .el-dialog__body {
    padding: 10px 20px !important;
}

.custom /deep/ .el-checkbox {
    height: auto !important;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom /deep/ .el-checkbox-group {
    display: flex;
    flex-wrap: wrap;
}

.custom /deep/ .el-checkbox__label {
    padding-left: 0px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.custom {
    height: calc(100vh - 500px);
    overflow-y: auto;
}

.csbbb {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    position: relative;
}

.ylsb {
    height: 50vh;
    overflow-y: auto;
    display: flex;
    flex-wrap: wrap;
}

.ylqs {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 7px;
    padding: 5px;
    cursor: pointer;
}

.eicon {
    display: none;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px;
    background: #dbdbdb;
}

.csbbb:hover .eicon {
    display: block;
}
</style>