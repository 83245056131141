import request from "@/utils/request";

export const  userLogin =(dataObj) =>{
    return request({
        url: "/authservice/yg/login",
        method:"post",
        data:dataObj
    })
}

// 获取验证码
export const  requestCode =(query) =>{
    return request({
        url: "/authservice/yg/requestCode",
        method:"get",
        params: query
    })
}

// 用户重置密码
export const  resetPassword =(data) =>{
    return request({
        url: "/authservice/yg/resetPassword",
        method:"post",
        data: data
    })
}

// 用户注册
export const  register =(data) =>{
    return request({
        url: "/authservice/yg/register",
        method:"post",
        data: data
    })
}

export const  codeLogin =(data) =>{
    return request({
        url: "/authservice/yg/codeLogin",
        method:"post",
        data:data
    })
}

export const  resetPwd =(data) =>{
    return request({
        url: "/authservice/yg/resetPassword",
        method:"post",
        data:data
    })
}

export const  userHasReg =(username) =>{
    return request({
        url: "/authservice/webGroup/userHasReg?username="+username,
        method:"get",
    })
}

export const  getMobileCode =(data) =>{
    return request({
        url: "/authservice/yg/requestCode",
        method:"post",
        data:data
    })
}

export const  isAuthGroup =(username) =>{
    return request({
        url: "/authservice/yg/isAdminUser?username="+username,
        method:"get",
    })
}

export const  scanCodeLogin =(username) =>{
    return request({
        url: "/webservice/scanCodeLogin",
        method:"get",
    })
}

export const  queryResult =(ewmcode) =>{
    return request({
        url: "/webservice/queryResult?ewmcode="+ewmcode ,
        method:"get",
    })
}
// 查询校区列表
export const  querySchoolList =(query) =>{
    return request({
        url: "/businessservice/orgPersonnel/querySchoolList",
        method:"get",
        params: query
    })
}
// 获取Code
export const  oauthCode =(data) =>{
    return request({
        url: "/api/oauth/code",
        method:"post",
        data:data
    })
}
// 授权验证码登录
export const socialCodeLogin = (data) => {
    return request({
        url: "/authservice/yg/social/codeLogin",
        method: "post",
        data: data
    })
}
// 授权账号密码登录
export const socialLogin = (data) => {
    return request({
        url: "/authservice/yg/social/login",
        method: "post",
        data: data
    })
}